import { useContext, useState } from "react";
import _ from "lodash";
import { AppContext, AppContextValue } from "../../../app/context";
import AssetsApi from "../../../common/api/assets";
import { ISnackbar } from "../../../common/interfaces";
import { Button, Checkbox, Modal } from "@cimpress/react-components";
import SnackbarWrapper, { danger, success } from "../../../common/components/snackbarwrapper";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import { useHistory } from "react-router-dom";

export default function DeleteAssetModal({
  showDeleteAssetModal,
  toggleDeleteAssetModal,
  assetId,
  assetIds,
  etag,
}: {
  showDeleteAssetModal: boolean;
  toggleDeleteAssetModal: any;
  assetId?: string;
  assetIds?: string[];
  etag: string;
}) {

  const history = useHistory();
  const { client }: AppContextValue = useContext(AppContext);
  const assetsApi = new AssetsApi(client);

  const [purge, setPurge] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [snackbarState, setSnackbarState] = useState<ISnackbar>({
    show: false,
    message: "",
    status: success,
  });

  const onDeleteAssetClick = async () => {
    setLoading(true);
    try {
      let result;

      if (assetId === undefined && assetIds.length > 0) {
        const promiseArr = assetIds.map(_assetId => {
          if (purge) {
            return assetsApi.purgeAsset(_assetId, etag);
          } else {
            return assetsApi.deleteAsset(_assetId, etag);
          }
        });

        const allResult = await Promise.all(promiseArr);
        if (allResult.length > 0 && allResult[0].success) {
          result = allResult[0];
        }
      } else {
        if (purge) {
          result = await assetsApi.purgeAsset(assetId, etag);
        } else {
          result = await assetsApi.deleteAsset(assetId, etag);
        }
      }

      setLoading(false);

      if (result && result.success) {
        if (assetId === undefined && assetIds.length > 0) {
          toggleDeleteAssetModal(true);
        } else {
          toggleDeleteAssetModal();
        }

        setSnackbarState({
          show: true,
          status: success,
          message: "The asset(s) deleted successfully.",
        });

        if (assetId !== undefined) {
          setTimeout(() => history.push("/"), 1000);
        }
      } else {
        const detail: string = _.get(result, "response.detail", "");
        setSnackbarState({
          show: true,
          status: danger,
          message: "Asset deletion failed. " + detail,
        });
      }
    } catch (err) {
      if (isLoading) {
        setLoading(false);
      }

      const detail: string = _.get(err, "response.detail", "");
      setSnackbarState({
        show: true,
        status: danger,
        message: "Asset deletion failed. " + detail,
      });
    }
  };

  const onPurgeAssetChecked = () => setPurge(!purge);

  const hideSnackbar = () => setSnackbarState({ show: false, status: success, message: "" });

  return (
    <>
      <Modal
        className="addAssetToCollectionsModal"
        title="Are you sure you want to delete this Asset(s)?"
        show={showDeleteAssetModal}
        onRequestHide={toggleDeleteAssetModal}
        closeOnOutsideClick={true}
        footer={
          <>
            {isLoading && <Spinner size="medium" />}
            <Button variant="default" onClick={toggleDeleteAssetModal} data-testid="cancel">
              Cancel
            </Button>
            &nbsp;
            <Button variant="primary" style={{ float: "right" }} onClick={onDeleteAssetClick} data-testid="finalDelete">
              Delete
            </Button>
          </>
        }
      >
        <div style={{ marginLeft: "15px" }}>
          <h5>Note: Asset will be retained in the system for 90 days after deletion</h5>
          <h5 style={{ marginTop: "1em" }}>Note: Purged assets are immediately removed</h5>
          <div style={{ marginTop: "2em" }}>
            <Checkbox data-testid="cbPurgeAsset" label="Purge asset" checked={purge} onChange={onPurgeAssetChecked} />
          </div>
        </div>
      </Modal>
      <SnackbarWrapper {...snackbarState} onHideSnackbar={hideSnackbar} />
    </>
  );
}
