import { useContext, useState, useEffect } from "react";
import { getCreatePostBodyForFile } from "../../../pages/create-asset/create-asset-helper";
import AssetsApi from "../../api/assets";
import CommonApi from "../../api/common";
import { AppContext, AppContextValue } from "../../../app/context";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import PlatformUploader from "@cimpress-technology/react-platform-uploader";
import Spinner from "@cimpress/react-components/lib/shapes/Spinner";
import _ from "lodash";

export default function index() {
  let imagesCollection = [];
  const NoOfWebPreviewCheckAttempt = 1800;
  const STREAM_ID = "st-z5lj5zq3l2";
  const APP_ID = "39802656-3034-11ed-afba-026a3228f481";
  const [tableAssetData, setTableAssetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { client, auth }: AppContextValue = useContext(AppContext);
  const assetsApi = new AssetsApi(client);
  const commonApi = new CommonApi(client);
  const customMessages = {
    dropFilesHere: "Drag & Drop from your computer",
    dragAndDropDocument: "Drag & Drop from your computer",
    or: "or",
    chooseFile: "Upload a file",
  };
  const lookerReport = process.env.REACT_APP_LOOKER_REPORT_URL+ auth?.getProfile().email;

  const columns = [
    {
      dataField: "imageName",
      text: "Asset Name",
      sort: true,
      align: "left",
      headerStyle: { width: "16.66%" },
      istoggle: true,
      csvExport: true,
    },
    {
      dataField: "imageSize",
      text: "Asset Size",
      sort: true,
      align: "left",
      headerStyle: { width: "16.66%" },
      istoggle: true,
      csvExport: true,
    },
    {
      dataField: "assetCreateDuration",
      text: "Asset Create Duration",
      sort: true,
      align: "left",
      headerStyle: { width: "16.66%" },
      istoggle: true,
      csvExport: true,
    },
    {
      dataField: "id",
      text: "Asset Id",
      sort: true,
      align: "left",
      headerStyle: { width: "16.66%" },
      istoggle: true,
      csvExport: true,
    },
    {
      dataField: "statusCode",
      text: "Status Code",
      sort: true,
      align: "left",
      headerStyle: { width: "16.66%" },
      istoggle: true,
      csvExport: true,
    },
    {
      dataField: "webPreviewDuration",
      text: "WebPrivew Duration",
      sort: true,
      align: "left",
      headerStyle: { width: "16.66%" },
      istoggle: true,
      csvExport: true,
    },
  ];
  const options = {
    sizePerPage: 10,
    hideSizePerPage: true,
  };
  const { ExportCSVButton } = CSVExport;

  const checkWebPreview = (fileObj, pendingAttempt, updatedTableData, webPreviewCounter) => {
    let assetObj = _.cloneDeep(fileObj);
    assetsApi.getAssetWebPreview(assetObj.id, { showDeleted: true, throwError: false }).then((res) => {
      if (res.status == 200) {
        let webPreviewEndTime = performance.now();
        updatedTableData.splice(webPreviewCounter, 1);
        assetObj.webPreviewDuration = ((webPreviewEndTime - assetObj.assetCreateEndTime) / 1000).toFixed(2) + " s";
        updatedTableData.splice(webPreviewCounter, 0, assetObj);
        let localTableData = updatedTableData.map((obj) => (obj.imageName === assetObj.imageName ? assetObj : obj));
        setTableAssetData(localTableData);
        let lookerData = [
          {
            streamId: STREAM_ID,
            appId: APP_ID,
            maxRetryCount: 3,
            retryDuration: 15,
            jsonObj: assetObj,
          },
        ];
        commonApi.sendDataToLooker(JSON.stringify(lookerData));
      }
      if (res.status > 200 && pendingAttempt > 0)
        setTimeout(() => checkWebPreview(assetObj, pendingAttempt - 1, updatedTableData, webPreviewCounter), 100);
    });
  };

  async function createAssetAndPublish(prevTableData) {
    let updatedTableData = [];
    let tableData = _.cloneDeep(prevTableData);
    for (let assetCounter = 0; assetCounter < prevTableData.length; assetCounter++) {
      let fileObj = tableData[assetCounter];
      tableData.splice(assetCounter, 1);
      let tempFileObj = _.cloneDeep(fileObj);
      let assetCreationStatus = "pending";
      let createAssetBody = {
        file: tempFileObj.file,
        expires: null,
        tags: [],
      };
      let assetId;
      let response;
      let assetCreateStartTime = performance.now();
      const res = await assetsApi.createAsset(true, getCreatePostBodyForFile(createAssetBody)).then((res) => {
        assetCreationStatus = res.status;
        assetId = res.response.id;
        response = res.response;
      });
      let assetCreateEndTime = performance.now();
      tempFileObj.id = assetId;
      tempFileObj.assetCreateDuration = ((assetCreateEndTime - assetCreateStartTime) / 1000).toFixed(2) + " s";
      tempFileObj.statusCode = assetCreationStatus;
      tempFileObj.response = response;
      tempFileObj.status = setLoading(false);
      tempFileObj.assetCreateEndTime = assetCreateEndTime;
      tableData.splice(assetCounter, 0, tempFileObj);
      updatedTableData.push(tempFileObj);
      let localTableData = tableData.map((obj) => (obj.imageName === fileObj.imageName ? tempFileObj : obj));
      setTableAssetData(localTableData);
      checkWebPreview(tableData[assetCounter], NoOfWebPreviewCheckAttempt, updatedTableData, assetCounter);
    }
  }

  const onSubmit = () => {
    let imageData = {};
    let tableData = [];
    for (let i = 0; i < imagesCollection.length; i++) {
      imageData = {
        id: null,
        imageName: imagesCollection[i].name,
        imageSize: (imagesCollection[i].size / 1024).toFixed(2) + " KB",
        assetCreateDuration: "....",
        statusCode: setTableOption(),
        response: null,
        webPreviewDuration: "....",
        assetCreateEndTime: null,
        file: imagesCollection[i],
        userEmail: auth?.getProfile().email,
        env: process.env.REACT_APP_ENV,
      };
      tableData.push(imageData);
    }

    setTableAssetData(tableData);
    createAssetAndPublish(tableData);
  };

  const onFileUpload = (files) => {
    imagesCollection = files;
    setLoading(true);
    onSubmit();
  };

  const setTableOption = () => {
    return (
      <div style={{ display: "flex", justifyContent: "left" }}>
        <Spinner size="small" />
      </div>
    );
  };

  return (
    <>
      <div className="row">
        <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
          <form onSubmit={onSubmit} style={{ width: "70%" }}>
            <div className="h-100 d-flex align-items-center justify-content-center">
              <PlatformUploader onDrop={onFileUpload} messages={customMessages} />
            </div>
          </form>
        </div>
      </div>
      <ToolkitProvider keyField="imageName" data={tableAssetData} columns={columns} search exportCSV>
        {(props) => (
          <div key={props.imageName}>
            <div>
              <div style={{ display: "flex", paddingBottom: "10px" }}>
                <div
                  style={{ paddingLeft: "1%", fontFamily: "Gotham-Rounded-Bold", width: "91%", alignSelf: "center" }}
                ></div>
                <div
                  className="col-xs-6 col-sm-6 col-md-6 col-lg-4 customPerformButton customExportCsv"
                  style={{ display: "contents", paddingLeft: "5px" }}
                >
                  <div>
                    <a href={lookerReport} style={{ cursor: "pointer" }} target="_blank">
                      Click here to compare
                    </a>
                  </div>
                  <ExportCSVButton {...props.csvProps}>
                    <i className="fa fa-download"></i>Export to CSV
                  </ExportCSVButton>
                </div>
              </div>
            </div>
            <BootstrapTable {...props.baseProps} pagination={paginationFactory(options)} />
          </div>
        )}
      </ToolkitProvider>
      {loading ? (
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spinner size="large" />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
