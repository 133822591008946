import { Button, Modal, TextField } from "@cimpress/react-components";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const CreateLink = ({
  sourceAssetId,
  showCreateLinkModal,
  toggleCreateLinkModal,
}: {
  sourceAssetId: string;
  showCreateLinkModal: boolean;
  toggleCreateLinkModal: any;
}) => {
  const history = useHistory();
  const [linkType, setLinkType] = useState<string>("");

  useEffect(() => {
    setLinkType("");
  }, [showCreateLinkModal]);

  const onLinkTypeChange = ({ target }) => setLinkType(target.value);

  const rediectToCreateAsset = () => history.push(`/assets/create/linking-asset/${sourceAssetId}/${linkType}`);

  return (
    <Modal
      show={showCreateLinkModal}
      onRequestHide={toggleCreateLinkModal}
      closeOnOutsideClick={true}
      title="Create Linked Asset"
      footer={
        <>
          <Button variant="default" onClick={toggleCreateLinkModal} data-testid="cancel">
            Cancel
          </Button>
          &nbsp;
          <Button
            variant="primary"
            onClick={rediectToCreateAsset}
            data-testid="proceedToCreateAsset"
            disabled={linkType === ""}
          >
            Proceed to Create Linked Asset
          </Button>
        </>
      }
    >
      <TextField label="Link Type" data-testid="linkType" value={linkType} onChange={onLinkTypeChange} />
      <h5>Note: After entering link type, you will be redirected to create a linked asset.</h5>
    </Modal>
  );
};

export default CreateLink;
