import _ from "lodash";
import { colors } from "@cimpress/react-components";
import AssetsApi from "../../../common/api/assets";

const nodeDetails = {};

export interface Node {
  id: string;
  label: string;
  color: string;
}

export interface Link {
  id: string;
  from: string;
  to: string;
  label: string;
}

export interface LinksGraphData {
  nodes: Node[];
  edges: Link[];
}

const linkGraphConfig = {
  clickToUse: false,
  layout: {
    hierarchical: false,
  },
  interaction: {
    tooltipDelay: 10000,
    navigationButtons: true,
    keyboard: false,
    hover: true,
    multiselect: true,
    hoverConnectedEdges: false,
  },
  physics: {
    forceAtlas2Based: {
      gravitationalConstant: -26,
      centralGravity: 0.005,
      springLength: 230,
      springConstant: 0.18,
      avoidOverlap: 1.5,
    },
    maxVelocity: 146,
    solver: "forceAtlas2Based",
    timestep: 0.35,
    stabilization: {
      enabled: true,
      iterations: 1000,
      updateInterval: 25,
    },
  },
  nodes: {
    font: {
      size: 13,
    },
    shape: "dot",
    size: 20,
  },
  edges: {
    hoverWidth: 1.5,
    arrows: {
      to: {
        enabled: true,
        scaleFactor: 1,
        type: "arrow",
      },
    },
    color: {
      color: "#d3d3d3",
      highlight: "#000000",
      hover: "#000000",
      inherit: false,
    },
    font: {
      size: 12,
      color: "black",
      strokeWidth: 6,
      strokeColor: "white",
    },
    smooth: true,
  },
  height: "400px",
};

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const getNodes = async (nodeSet: Set<string>, assetId: string, assetsApi: AssetsApi) => {
  const nodes: Node[] = [];
  const assetCalls = [];
  nodeSet.forEach((node: string) => {
    if (node === assetId) {
      nodes.push({
        id: node,
        label: "This Asset",
        color: colors.linkPrimary,
      });
    } else {
      assetCalls.push(assetsApi.getAssetVersions(node));
    }
  });

  if (assetCalls.length > 0) {
    try {
      const respArr = await Promise.all(assetCalls);
      if (respArr.length > 0) {
        respArr.forEach((data: any) => {
          if (data && data.success) {
            const asset = _.get(data, "response._embedded.item[0]", null);

            if (asset) {
              const fileName = _.get(asset, "info.storage.fileName", "");
              nodeDetails[asset.id] = {
                fileName,
                size: _.get(asset, "info.storage.fileSizeBytes", ""),
                tags: _.get(asset, "tags", []),
                owner: _.get(asset, "createdBy", ""),
              };
              nodes.push({
                id: asset.id,
                label: fileName,
                color: getRandomColor(),
              });
            }
          }
        });
      }
    } catch (e) {
      console.log("getAssetNames: ", e);
    }
  }
  return nodes;
};

const getNodeDetails = () => nodeDetails;

const setFakeNodeDetails = (assetId) =>
  (nodeDetails[assetId] = { fileName: "fake", size: "0 KB", tags: ["fake"], owner: "fake@gmail.com" });

export interface TableLink {
  id: string;
  sourceAssetId: string;
  targetAssetId: string;
  targetAssetFileName: string;
  linkType: string;
}

const getTableLinks = (links: LinksGraphData) => {
  const tableLinks: TableLink[] = [];

  if (links.edges.length > 0) {
    const nodes: Node[] = _.get(links, "nodes", []);

    links.edges.forEach((edge: any) => {
      const { id, from, to, label } = edge;
      const linkedAsset: Node = nodes.find((node) => node.id === to);

      if (linkedAsset) {
        tableLinks.push({
          id: id,
          sourceAssetId: from,
          targetAssetId: linkedAsset.id,
          targetAssetFileName: linkedAsset.label,
          linkType: label,
        });
      }
    });
  }

  return tableLinks;
};

export { linkGraphConfig, getRandomColor, getNodes, getNodeDetails, setFakeNodeDetails, getTableLinks };
