import { useEffect, useState } from "react";
import { Button, Tag, TextField } from "@cimpress/react-components";
import { TagData } from "../../../../common/interfaces";
import "./tagsForm.css";

export default function TagsForm({ onTagsChange, defaultTags, showDescription = true }) {
  const [tempTagValue, setTempTagValue] = useState<string>("");
  const [tags, setTags] = useState<TagData[]>([]);

  useEffect(() => {
    if (defaultTags && defaultTags.length > 0) {
      const _tags = defaultTags.map((t) => {
        return {
          value: t,
          exists: true,
        };
      });
      setTags(_tags);
      onTagsChange("tags", _tags);
    }
  }, [defaultTags]);

  const onTagValueChange = ({ target }) => {
    let value = target.value;
    value = value.trim();
    setTempTagValue(value);
  };

  const onTagKeyPress = ({ key }: { key: string }) => {
    if (key === "Enter") {
      onAddTagClick();
    }
  };

  const onAddTagClick = () => {
    if (tempTagValue !== "" && tempTagValue.trim() !== "") {
      const _tags: TagData[] = [...tags];
      const newtag: TagData = {
        value: tempTagValue.trim(),
        exists: false,
      };
      _tags.push(newtag);
      setTags(_tags);
      setTempTagValue("");
      onTagsChange("tags", _tags);
    }
  };

  const onTagRemoveClick = (value: string) => {
    const _tags: TagData[] = [...tags];
    const newTags = _tags.filter((tag) => tag.value !== value);
    setTags(newTags);
    onTagsChange("tags", newTags);
  };

  return (
    <div className="row">
      <div className="col-md-12" style={{ marginLeft: "-15px" }}>
        <label data-testid="lblTags">Tags</label>
        <br />
        {showDescription ? (
          <span data-testid="descTags">
            Tags help you filter and search for assets in Asset Management. You can create and add as many tags for your
            asset as you need. E.g. 'Premium Finish', 'Large', 'PPAG', and so on.
          </span>
        ) : null}
        <div className="row" style={{ marginTop: "15px" }}>
          <div className="col-md-7">
            <TextField
              data-testid="txtTag"
              label="Tag"
              value={tempTagValue}
              onChange={onTagValueChange}
              onKeyPress={onTagKeyPress}
            />
          </div>

          <div className="col-md-3" style={{ paddingLeft: "20px" }}>
            <Button data-testid="addTag" variant="default" style={{ marginTop: "5px" }} onClick={onAddTagClick}>
              Add Tag
            </Button>
          </div>
        </div>

        {tags.length > 0 ? (
          <>
            {tags.map((tag) => (
              <Tag
                key={tag.value}
                {...tag}
                removable={true}
                onRemoveClick={onTagRemoveClick}
                style={{ marginRight: "5px" }}
                className={""}
              />
            ))}
          </>
        ) : (
          <span>No tags added.</span>
        )}

        <br />
        <br />
      </div>
    </div>
  );
}
