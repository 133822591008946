import React, { useState } from "react";
import { Button, colors, Modal, Select, TextField } from "@cimpress/react-components";
import { IconBin } from "@cimpress-technology/react-streamline-icons/lib";
import { DropdownOptions } from "../../../../common/interfaces";
import "../search.css";

const deleteOptionStyle: React.CSSProperties = {
  marginTop: "0.5em",
  cursor: "pointer",
};

const options: DropdownOptions[] = [
  { value: "tags", label: "Tags" },
  { value: "metadata", label: "Meta Data" },
  { value: "assetId", label: "Asset ID" },
  { value: "owner", label: "Owner" },
];

export interface SearchOptions {
  id: number;
  type: DropdownOptions;
  keyword: string;
  metaKeyValue: any;
}

const defaultSearchOptions: SearchOptions = {
  id: 0,
  type: null,
  keyword: "",
  metaKeyValue: {},
};

export interface SearchTypeKeyword {
  label: string;
  keyword: string;
}

const getSearchPayload = (searchOptions: SearchOptions[]) => {
  const searchPayload = {},
    searchTypeKeyword: SearchTypeKeyword[] = [];
  searchOptions.forEach((ele) => {
    if (ele.type) {
      let metaDataKeyValue: Object,
        keyword: string = ele.keyword;
      if (ele.type.value === "metadata") {
        metaDataKeyValue = { [`${ele.metaKeyValue["key"]}`]: ele.metaKeyValue["value"] };
      }

      searchPayload[ele.type.value] = ele.type.value === "metadata" ? metaDataKeyValue : keyword;

      searchTypeKeyword.push({
        label: ele.type.label,
        keyword: ele.type.value === "metadata" ? JSON.stringify(metaDataKeyValue) : ele.keyword,
      });
    }
  });
  return {
    searchPayload,
    searchTypeKeyword,
  };
};

const SearchOptionsModal = ({
  showSearchOptionsModal,
  toggleSearchOptionsModal,
  onSearchFromSearchOptions,
}: {
  showSearchOptionsModal: boolean;
  toggleSearchOptionsModal: any;
  onSearchFromSearchOptions: any;
}) => {
  const [searchOptions, setSearchOptions] = useState<SearchOptions[]>([defaultSearchOptions]);

  const onTypeSelectionChange = ({ type, index }: { type: DropdownOptions; index: number }) => {
    if (type && type.value === "assetId") {
      const _searchOptions: SearchOptions[] = [{
        id: 0,
        keyword: "",
        metaKeyValue: "",
        type
      }];
      setSearchOptions(_searchOptions);
    } else {
      const _searchOptions: SearchOptions[] = [...searchOptions];
      _searchOptions[index].type = type;
      setSearchOptions(_searchOptions);
    }
  };

  const onKeywordChange = ({ keyword, index }: { keyword: string; index: number }) => {
    const _searchOptions: SearchOptions[] = [...searchOptions];
    _searchOptions[index].keyword = keyword;
    setSearchOptions(_searchOptions);
  };

  const onMetaKeyChange = ({ metaKey, index }: { metaKey: string; index: number }) => {
    const _searchOptions: SearchOptions[] = [...searchOptions];
    _searchOptions[index].metaKeyValue = {
      key: metaKey,
      value: _searchOptions[index].metaKeyValue["value"],
    };
    setSearchOptions(_searchOptions);
  };

  const onMetaValueChange = ({ metaValue, index }: { metaValue: string; index: number }) => {
    const _searchOptions: SearchOptions[] = [...searchOptions];
    _searchOptions[index].metaKeyValue = {
      key: _searchOptions[index].metaKeyValue["key"],
      value: metaValue,
    };
    setSearchOptions(_searchOptions);
  };

  const addOptionsClick = () => {
    const _searchOptions: SearchOptions[] = [...searchOptions];
    const newSearchOption: SearchOptions = {
      id: _searchOptions[_searchOptions.length - 1].id + 1,
      type: null,
      keyword: "",
      metaKeyValue: {},
    };
    _searchOptions.push(newSearchOption);
    setSearchOptions(_searchOptions);
  };

  const deleteAddedOptions = (index: number) => {
    const _searchOptions: SearchOptions[] = [...searchOptions];
    _searchOptions.splice(index, 1);
    setSearchOptions(_searchOptions);
  };

  const onSearchClick = () => {
    onSearchFromSearchOptions(getSearchPayload(searchOptions));
    toggleSearchOptionsModal();
  };

  return (
    <Modal
      className="searchOptionsModal"
      show={showSearchOptionsModal}
      onRequestHide={toggleSearchOptionsModal}
      closeOnOutsideClick={true}
      footer={
        <>
          <Button variant="default" onClick={toggleSearchOptionsModal} data-testid="cancel">
            Cancel
          </Button>
          &nbsp;
          <Button variant="primary" onClick={onSearchClick} data-testid="search">
            Search
          </Button>
        </>
      }
    >
      <>
        <div className="row">
          <div className="col-md-12">
            <label>Search By</label>
          </div>
        </div>
        {searchOptions.map((ele, index) => (
          <div className="row" key={ele.id}>
            <div className="col-md-4">
              <Select
                isClearable
                label="Select Type"
                options={options}
                value={ele.type}
                onChange={(type) => onTypeSelectionChange({ type, index })}
              />
            </div>
            <div className={`${index === 0 ? "col-md-8" : "col-md-6"}`}>
              {ele.type && ele.type.value === "metadata" ? (
                <div style={{ marginLeft: "-1em" }}>
                  <div className="col-md-6">
                    <TextField
                      label="Meta Key"
                      onChange={({ target }) => onMetaKeyChange({ metaKey: target.value, index })}
                      value={ele.metaKeyValue && ele.metaKeyValue["key"]}
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      label="Meta Value"
                      onChange={({ target }) => onMetaValueChange({ metaValue: target.value, index })}
                      value={ele.metaKeyValue && ele.metaKeyValue["value"]}
                    />
                  </div>
                </div>
              ) : (
                <TextField
                  label="Search Keyword"
                  data-testid="searchKeyword"
                  onChange={({ target }) => onKeywordChange({ keyword: target.value, index: index })}
                  value={ele.keyword}
                />
              )}
            </div>
            {index > 0 && (
              <div className="col-md-2">
                <IconBin
                  data-testid="deleteAddedOptions"
                  color={colors.ocean.base}
                  style={deleteOptionStyle}
                  size="2x"
                  onClick={() => deleteAddedOptions(index)}
                />
              </div>
            )}
          </div>
        ))}
        {searchOptions.length < options.length && (
          <div className="row">
            <div className="col-md-12">
              <Button variant="anchor" onClick={addOptionsClick} data-testid="addOptions">
                + another option
              </Button>
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};

export default SearchOptionsModal;
export { getSearchPayload };
