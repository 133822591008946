import { Button, TextField, Modal } from "@cimpress/react-components";
import { useState, useContext } from "react";
import _ from "lodash";
import { AppContext, AppContextValue } from "../../../../app/context";
import LinksApi from "../../../../common/api/links";
import { danger, success } from "../../../../common/components/snackbarwrapper";
import { Spinner } from "@cimpress/react-components/lib/shapes";

const EditLinkModal = ({
  showModal,
  toggleModal,
  onDisplayMessage,
  linkId,
  sourceAssetId,
  targetAssetId,
  orgLinkType,
  assetName,
}: {
  showModal: boolean;
  toggleModal: any;
  onDisplayMessage: any;
  linkId: string;
  sourceAssetId: string;
  targetAssetId: string;
  orgLinkType: string;
  assetName: string;
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [linkType, setLinkType] = useState<string>(orgLinkType);

  const { client }: AppContextValue = useContext(AppContext);

  const onEditLink = () => {
    const linksApi = new LinksApi(client);

    setLoading(true);

    linksApi
      .putLink(linkId, "*", {
        sourceAssetId,
        targetAssetId,
        linkType,
      })
      .then((result) => {
        setLoading(false);

        if (result && result.status) {
          onDisplayMessage({
            message: "Link type updated successfully.",
            status: success,
          });
        } else {
          const detail: string = _.get(result, "response.detail", "");
          onDisplayMessage({
            message: "Link type updation failed. " + detail,
            status: danger,
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        onDisplayMessage({
          message: "Link type updation failed. " + e.message,
          status: danger,
        });
      });
  };

  const onLinkTypeChange = ({ target }) => setLinkType(target.value);

  return (
    <>
      <Modal
        show={showModal}
        onRequestHide={toggleModal}
        closeOnOutsideClick={true}
        title="Edit Link Type"
        footer={
          <>
            {isLoading && <Spinner size="medium" />}
            <Button variant="default" onClick={toggleModal} data-testid="cancel">
              Cancel
            </Button>
            &nbsp;
            <Button
              variant="primary"
              data-testid="saveChanges"
              disabled={linkType === orgLinkType}
              onClick={onEditLink}
            >
              Save Changes
            </Button>
          </>
        }
      >
        <TextField label="Link Type" data-testid="linkType" value={linkType} onChange={onLinkTypeChange} />
        <h5>Note: You are editing link type of Linked Asset: {assetName}</h5>
      </Modal>
    </>
  );
};

export default EditLinkModal;
