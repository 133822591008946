import { useEffect, useState } from "react";
import { Button, colors, Radio, RadioGroup, Select, TextField, Toggle } from "@cimpress/react-components";
import { css, cx } from "emotion";
import PlatformUploader from "@cimpress-technology/react-platform-uploader";
import { DropdownOptions, ISnackbar } from "../../../../common/interfaces";
import PreviewImage from "../../preview-image";
import { IconRemove } from "@cimpress-technology/react-streamline-icons/lib";
import SnackbarWrapper, { warning, success, danger } from "../../../../common/components/snackbarwrapper";

const invalidFileExtensions = [
  "exe",
  "scr",
  "msi",
  "bat",
  "sh",
  "cmd",
  "com",
  "dll",
  "pif",
  "scr",
  "vb",
  "vbe",
  "vbs",
  "ws",
  "wsc",
  "wsf",
  "wsh",
];

const cancleButtonStyle: string = css({
  height: "48px",
});

const fileCancelStyle: string = cx("btn btn-default", cancleButtonStyle);

const customMessages = {
  dropFilesHere: "Drag & Drop from your computer",
  dragAndDropDocument: "Drag & Drop from your computer",
  or: "or",
  chooseFile: "Upload a file",
};

const expiryOptions: DropdownOptions[] = [
  { value: "P7D", label: "7 Days" },
  { value: "P30D", label: "30 Days" },
  { value: "P90D", label: "90 Days" },
  { value: "never", label: "Never" },
];

interface UploadedFile {
  filename: string;
  bloburl: string;
}

const BasicInfoForm = ({ onBasicInfoChange, showManagedToggle = true, defaultBasicInfo }) => {
  const [snackbarState, setSnackbarState] = useState<ISnackbar>({
    show: false,
    message: "",
    status: success,
  });

  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(true);
  const [file, setFile] = useState<UploadedFile>({
    filename: "",
    bloburl: "",
  });

  const [uri, setUri] = useState<string>("");
  const [expiry, setExpiry] = useState<DropdownOptions>(null);

  useEffect(() => {
    if (defaultBasicInfo) {
      const _expiry = expiryOptions.filter((ops) => ops.value === defaultBasicInfo.expires);
      if (_expiry.length > 0) {
        setExpiry(_expiry[0]);
      }
    }
  }, [defaultBasicInfo]);

  const onAssetCreateOptionsChanged = (e: any) => {
    e.preventDefault();
    const _isFileUploaded = !isFileUploaded;
    setIsFileUploaded(_isFileUploaded);
    onBasicInfoChange("isFileUploaded", _isFileUploaded);
  };

  const onFileUpload = (file) => {
    if (file.length > 1) {
      setSnackbarState({
        show: true,
        status: warning,
        message: "You need to select only one file to upload.",
      });
      return;
    }
    const uploadedFile = file[0];
    const fileExtension = uploadedFile.name.split(".").pop();

    if (uploadedFile.type === "application/x-msdownload" || invalidFileExtensions.includes(fileExtension)) {
      setSnackbarState({
        show: true,
        status: danger,
        message: "The file is not allowed to be uploaded.",
      });
      return;
    }

    let bloburl = "";
    // if the file is not an application [except pdf], we need to convert it to blob url
    if (!(uploadedFile.type.includes("application") && uploadedFile.type !== "application/pdf")) {
      bloburl = URL.createObjectURL(uploadedFile);
    }

    setFile({
      filename: uploadedFile.name,
      bloburl,
    });
    onBasicInfoChange("file", uploadedFile);
  };

  const onFileRemove = () => {
    setFile({
      filename: "",
      bloburl: "",
    });
    onBasicInfoChange("file", null);
  };

  const onUriChange = ({ target }) => {
    setUri(target.value);
    onBasicInfoChange("uri", target.value);
  };

  const onExpiryChange = (selected) => {
    setExpiry(selected);
    onBasicInfoChange("expires", selected);
  };

  const hideSnackbar = () => setSnackbarState({ show: false, status: success, message: "" });

  const doNothing = () => console.log("nothing");

  return (
    <>
      <div className="row">
        <label data-testid="lblBasicInfo">Basic Info</label>
      </div>
      <div className="row">
        <RadioGroup
          data-testid="assetCreateOptions"
          name="assetCreateOptions"
          inline={true}
          defaultSelected={"uploadAsset"}
          onChange={onAssetCreateOptionsChanged}
        >
          <Radio label="Upload Asset File" value="uploadAsset" />
          <Radio label="Paste Asset URL" value="pasteAsset" />
        </RadioGroup>
      </div>
      <div className="row">
        <div className="col-md-8" style={{ marginLeft: "-15px" }}>
          {isFileUploaded ? (
            <>
              <PlatformUploader onDrop={onFileUpload} messages={customMessages} />
              <br />
              <TextField
                label="Asset File Name"
                disabled={true}
                value={
                  file.filename !== ""
                    ? file.filename
                    : defaultBasicInfo && defaultBasicInfo.fileName !== ""
                    ? defaultBasicInfo.fileName
                    : ""
                }
                onChange={doNothing}
                helpText="(named after uploaded asset filename)"
                rightAddon={
                  <Button disabled={file.filename === ""} className={fileCancelStyle} onClick={onFileRemove}>
                    <IconRemove color={colors.ocean.base} size="lg" />
                  </Button>
                }
              />
            </>
          ) : (
            <>
              <TextField label="URL" value={uri} onChange={onUriChange} />
            </>
          )}

          <span data-testid="lblExpires">Asset expires after:</span>
          <br />
          <div className="col-md-4" style={{ marginLeft: "-15px", marginTop: "5px" }}>
            <Select label="Days to Expiry" options={expiryOptions} value={expiry} onChange={onExpiryChange} />
          </div>
        </div>
        <div className="col-md-4" style={{ marginBottom: "15px" }}>
          <PreviewImage
            imageUrl={
              isFileUploaded && file.bloburl !== ""
                ? file.bloburl
                : !isFileUploaded && uri !== ""
                ? uri
                : defaultBasicInfo && defaultBasicInfo.uri !== ""
                ? defaultBasicInfo.uri
                : uri
            }
            fileName={defaultBasicInfo && defaultBasicInfo.uri !== "" ? defaultBasicInfo.fileName : ""}
          />
        </div>
      </div>
      <SnackbarWrapper {...snackbarState} onHideSnackbar={hideSnackbar} />
    </>
  );
};

export default BasicInfoForm;
