import { Button, Table, Dropdown } from "@cimpress/react-components";
import { useState, useMemo } from "react";
import _ from "lodash";
import { TableLink } from "../links-helper";
import EditLinkModal from "../edit-link";
import DeleteLinkModal from "../delete-link";
import { ISnackbar } from "../../../../common/interfaces";
import SnackbarWrapper, { success } from "../../../../common/components/snackbarwrapper";

const EDIT_LINK = "editLink",
  DELETE_LINK = "deleteLink",
  manageActions = [
    { label: "Edit Link Type", value: EDIT_LINK },
    { label: "Delete Link", value: DELETE_LINK },
  ];

interface LinkModalData {
  linkId: string;
  sourceAssetId: string;
  targetAssetId: string;
  assetName: string;
  modalType: string;
  linkType: string;
  orgLinkType: string;
}

const defaultModalData: LinkModalData = {
  linkId: "",
  sourceAssetId: "",
  targetAssetId: "",
  assetName: "",
  modalType: "",
  linkType: "",
  orgLinkType: "",
};

const LinksTable = ({ links, onPageRefresh }: { links: TableLink[]; onPageRefresh: any }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<LinkModalData>(defaultModalData);
  const [snackbarState, setSnackbarState] = useState<ISnackbar>({
    show: false,
    message: "",
    status: success,
  });

  const actionsRenderer = (data) => {
    const onActionChange = (selected) => {
      const link: TableLink = _.get(data, "original", null);
      if (link !== null) {
        setModalData({
          linkId: link.id,
          sourceAssetId: link.sourceAssetId,
          targetAssetId: link.targetAssetId,
          assetName: link.targetAssetFileName !== "" ? link.targetAssetFileName : link.targetAssetId,
          linkType: link.linkType,
          orgLinkType: link.linkType,
          modalType: selected.value,
        });
        setShowModal(true);
      }
    };

    return (
      <Dropdown title="Actions" className="manageLinkActions">
        {manageActions.map((action) => {
          const _btnActionClick = () => onActionChange(action);
          return (
            <Button onClick={_btnActionClick} variant="anchor">
              {action.label}
            </Button>
          );
        })}
      </Dropdown>
    );
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Linked Asset",
        accessor: "targetAssetFileName",
      },
      {
        Header: "Linked Asset ID",
        accessor: "targetAssetId",
      },
      {
        Header: "Link Type",
        accessor: "linkType",
      },
      {
        Header: "Actions",
        accessor: null,
        Cell: actionsRenderer,
      },
    ];
  }, []);

  const onDisplayMessage = ({ message, status }) => {
    toggleModal();
    setSnackbarState({
      show: true,
      status,
      message,
    });

    if (status === success) {
      setTimeout(onPageRefresh, 1000);
    }
  };

  const toggleModal = () => setShowModal(!showModal);

  const hideSnackbar = () => setSnackbarState({ show: false, status: success, message: "" });

  return (
    <>
      <Table columns={columns} data={links} noDataText={"Really nothing here..."} />

      {showModal && modalData.modalType === EDIT_LINK && (
        <EditLinkModal
          showModal={showModal}
          toggleModal={toggleModal}
          onDisplayMessage={onDisplayMessage}
          linkId={modalData.linkId}
          sourceAssetId={modalData.sourceAssetId}
          targetAssetId={modalData.targetAssetId}
          orgLinkType={modalData.orgLinkType}
          assetName={modalData.assetName}
        />
      )}

      {showModal && modalData.modalType === DELETE_LINK && (
        <DeleteLinkModal
          showModal={showModal}
          toggleModal={toggleModal}
          onDisplayMessage={onDisplayMessage}
          linkId={modalData.linkId}
          linkType={modalData.orgLinkType}
          assetName={modalData.assetName}
        />
      )}
      <SnackbarWrapper {...snackbarState} onHideSnackbar={hideSnackbar} />
    </>
  );
};

export default LinksTable;
