import { useState, useContext, useEffect } from "react";
import { AppContext, AppContextValue } from "../../../app/context";
import AssetsApi from "../../../common/api/assets";
import { Button, Modal } from "@cimpress/react-components";
import { DropdownOptions, ISnackbar } from "../../../common/interfaces";
import SnackbarWrapper, { danger, success } from "../../../common/components/snackbarwrapper";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import _ from "lodash";
import "./hide-asset.css";

export default function HideAsset({
  assetIds,
  hide,
  toggleHideAsset,
}: {
  assetIds: any;
  hide: boolean;
  toggleHideAsset: any;
}) {
  const { client }: AppContextValue = useContext(AppContext);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [snackbarState, setSnackbarState] = useState<ISnackbar>({
    show: false,
    message: "",
    status: success,
  });

  const assetsApi = new AssetsApi(client);

  useEffect(() => {
    setLoading(true);
    if (hide) {
      assetsApi.hideAsset(assetIds).then((result) => {
        setLoading(false);
        if (result && result.success) {
          setSnackbarState({
            show: true,
            status: success,
            message: "Asset hidden successfully.",
          });
          toggleHideAsset();
        } else {
          setSnackbarState({
            show: true,
            status: success,
            message: "Failed hiding asset.",
          });
        }
      });
    } else {
      assetsApi.unHideAsset(assetIds).then((result) => {
        setLoading(false);
        if (result && result.success) {
          setSnackbarState({
            show: true,
            status: success,
            message: "Asset unhide successfully.",
          });
          toggleHideAsset();
        } else {
          setSnackbarState({
            show: true,
            status: success,
            message: "Failed unhiding asset.",
          });
        }
      });
    }
  }, [assetIds]);

  const hideSnackbar = () => setSnackbarState({ show: false, status: success, message: "" });

  return (
    <>
      <SnackbarWrapper {...snackbarState} onHideSnackbar={hideSnackbar} />
    </>
  );
}
