export interface LayoutProps {
  children: JSX.Element | JSX.Element[];
}
/**
 * Layout component, used to render different pages
 * @param props
 */
export default function Layout(props: LayoutProps) {
  return (
    <div className="container-fluid">
      <div
        className="content-wrapper"
        style={{ display: "flex", flexDirection: "column", backgroundColor: "transparent", padding: 0 }}
      >
        {props.children}
      </div>
    </div>
  );
}
