import HTTPClient from ".";
const queryString = require("query-string");

export default class FoldersApi {
  private readonly client: HTTPClient;
  private readonly controller: AbortController;

  constructor(client: HTTPClient) {
    this.client = client;
    this.controller = new AbortController();
  }

  cancelRequest() {
    this.controller.abort();
  }

  async getFolders(params?: any) {
    const requestOptions: RequestInit = {
      method: "GET",
      signal: this.controller.signal,
    };

    const qs = queryString.stringify(params);
    return this.client.sendRequest(`/v2/folders?${qs}`, requestOptions);
  }

  async createFolders(name: string, folderId: any) {
    let requestOptions: RequestInit = {
      method: "POST",
      body: JSON.stringify({
        name,
        folderId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      signal: this.controller.signal,
    };

    return this.client.sendRequest("/v2/folders", requestOptions);
  }
}
