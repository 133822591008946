import React, { useMemo } from "react";
import BasicInfoForm from "./basicinfoform";
import TagsForm from "./tagsform";
import Divider from "../divider";

export default function AssetForm(props: any) {
  const children = useMemo((): JSX.Element[] => {
    if (props.children === undefined) {
      return [];
    }

    if (Array.isArray(props.children)) {
      return props.children.map((child, index) => (
        <React.Fragment key={index}>
          <Divider />
          {child}
        </React.Fragment>
      ));
    } else {
      return [
        <React.Fragment key={0}>
          <Divider />
          {props.children}
        </React.Fragment>,
      ];
    }
  }, [props]);

  return (
    <>
      <div style={{ padding: "15px" }}>
        <div
          data-testid="asset-form"
          className="col-md-12"
          style={{ backgroundColor: "white", padding: "50px 50px 50px 65px" }}
        >
          <div>
            <BasicInfoForm
              onBasicInfoChange={props.onFormElementValueChange}
              showManagedToggle={props.showManagedToggle}
              defaultBasicInfo={
                props.assetVersionData && {
                  uri: props.assetVersionData._links.links._thumbnail[0].href,
                  expires: props.assetVersionData.info.asset.expires,
                  fileName:
                    props.assetVersionData &&
                    props.assetVersionData.info &&
                    props.assetVersionData.info.storage.fileName &&
                    props.assetVersionData.info.storage.fileName,
                }
              }
            />
            <TagsForm
              onTagsChange={props.onFormElementValueChange}
              defaultTags={props.assetVersionData && props.assetVersionData.tags}
            />
            {children}
          </div>
        </div>
      </div>
    </>
  );
}
