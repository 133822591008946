const COLLECTIONS = "collections";
const ASSETS = "assets";

const updatePropsForFilters = (resourceType: string, filters: any, filterProp: any) => {
    for (const prop in filters[resourceType]) {
        if (filters[resourceType][prop] !== "") {
            filterProp[prop] = filters[resourceType][prop];
        }
    }
}

export {
    COLLECTIONS,
    ASSETS,
    updatePropsForFilters
};