import { FourOhFour } from "@cimpress/react-components";
import HomePage from "../../pages/home";
import CreateAssetPage, { CreateAssetPageName } from "../../pages/create-asset";
import ManageAssetVersionsPage from "../../pages/manage-asset-versions";
import ViewAssetVersionPage from "../../pages/view-asset-version";
import UpdateAssetPage from "../../pages/update-asset";
import Uploads from "../../common/components/testUpload";

const Routes = [
  {
    path: "/",
    exact: true,
    children: <HomePage />,
    name: "homepage",
  },
  {
    path: "/assets/create",
    exact: true,
    children: <CreateAssetPage />,
    name: CreateAssetPageName,
  },
  {
    path: "/assets/create/:id/",
    exact: true,
    children: <CreateAssetPage />,
    name: CreateAssetPageName,
  },
  {
    path: "/assets/create/:id/:folderName",
    exact: true,
    children: <CreateAssetPage />,
    name: CreateAssetPageName,
  },
  {
    path: "/assets/create/linking-asset/:sourceAssetId/:linkType",
    exact: true,
    children: <CreateAssetPage />,
  },
  {
    path: "/assets/:assetId/versions",
    exact: true,
    children: <ManageAssetVersionsPage />,
    name: "manage-asset-version-page",
  },
  {
    path: "/assets/:assetId/versions/:versionId/:versionIndex",
    exact: true,
    children: <ViewAssetVersionPage />,
  },
  {
    path: "/test/upload",
    exact: true,
    children: <Uploads />,
    name: "Uploads",
  },
  {
    path: "/assets/update/:assetId/versions/:versionId/:versionIndex",
    exact: true,
    children: <UpdateAssetPage />,
  },
  {
    path: "*",
    children: (
      <FourOhFour
        message={<div>Oops! Something has gone wrong and the page you were looking for could not be displayed.</div>}
      />
    ),
  },
];
export default Routes;
