import { useContext, useEffect, useState } from "react";
import { AppContext, AppContextValue } from "../context";
import Header from "@cimpress-technology/react-platform-header";
import config from "../../config";
import { Button, Modal, TextField } from "@cimpress/react-components";
import IconUserSetting from "@cimpress-technology/react-streamline-icons/lib/IconUserSetting";
import IconUserActionsRemove from '@cimpress-technology/react-streamline-icons/lib/IconUserActionsRemove';
import { ISnackbar } from "../../common/interfaces";
import SnackbarWrapper, { danger, warning, success } from "../../common/components/snackbarwrapper";

/**
 * Renders common page header component
 */
export default function AppHeader() {
  const [showChangeTestURLModal, setShowChangeTestURLModal] = useState<boolean>(false);
  const [baseURL, setBaseURL] = useState(localStorage.getItem('sherbertBaseURL' ?? ""));
  const context: AppContextValue = useContext(AppContext);
  const [snackbarState, setSnackbarState] = useState<ISnackbar>({
    show: false,
    message: "",
    status: success,
  });

  const onBaseURLChange = ({ target }) => {
    setBaseURL(target.value);
  } 

  const handleClick = () => {
    setShowChangeTestURLModal(true);
  };

  const saveSherbertBaseURL = () => {
    localStorage.setItem("sherbertBaseURL", baseURL);
    setSnackbarState({
      show: true,
      status: success,
      message: "Sherbert BaseURL Saved",
    });
    setShowChangeTestURLModal(false);
    window.location.reload();
  };

  const hideSnackbar = () => {
    setSnackbarState({ show: false, status: success, message: "" });
  };

  const resetSherbertBaseUrl = () => {
    localStorage.removeItem("sherbertBaseURL");
    window.location.reload();
  }

  const applinks =
    process.env.REACT_APP_ENV !== "production"
      ? [
          {
            title: "settings",
            content: (
              <a style={{ cursor: "pointer" }}>
                {
                baseURL && baseURL.length > 0 ? <IconUserActionsRemove color="green" size="lg" onClick={handleClick} /> : <IconUserSetting size="lg" onClick={handleClick} />
                }
              </a>
            ),
          },
        ]
      : [];

  return (
    <>
      <Header
        accessToken={context.auth.getAccessToken()}
        appTitle={<a href="/">{config.applicationName}</a>}
        appLinks={applinks}
        documentationUrl={"https://cimpress-support.atlassian.net/wiki/spaces/CTSTOR/pages/1197540434/Sherbert"}
        showLeftNav={false}
        profile={context.auth.getProfile()}
        onLogInClicked={() => context.auth.login()}
        onLogOutClicked={() => context.auth.logout("/")}
        isLoggedIn={context.auth.isLoggedIn()}
      />
      <Modal
        show={showChangeTestURLModal}
        onRequestHide={() => {
          setShowChangeTestURLModal(false);
        }}
        closeOnOutsideClick={true}
        closeButton={true}
        title="Change Sherbert BaseURL For Test"
        footer={
          <>
            <Button
              variant="primary"
              onClick={resetSherbertBaseUrl}
              data-testid="resetBaseURL"
            >
              Reset
            </Button>
            <Button
              variant="primary"
              onClick={saveSherbertBaseURL}
              data-testid="updateBaseURL"
            >
              Save
            </Button>
            
          </>
        }
      >
        <TextField
          label="Sherbert Base URL Example : api.sherbert.example.cimpress.io"
          data-testid="SherbertBaseURL"
          value={baseURL}
          onChange={onBaseURLChange}
        />
      </Modal>
      <SnackbarWrapper {...snackbarState} onHideSnackbar={hideSnackbar} />
    </>
  );
}
