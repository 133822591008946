import { Button, TabCard } from "@cimpress/react-components";
import { useState, useEffect, useCallback, useContext, useMemo } from "react";
import _ from "lodash";
import { AppContext, AppContextValue } from "../../../app/context";
import AssetsApi from "../../../common/api/assets";
import LinksApi from "../../../common/api/links";
import { LinksQueryBody } from "../../../common/api/links";
import { Link, LinksGraphData, getNodes, getTableLinks, TableLink } from "./links-helper";
import LinksNetwork from "./network";
import LinksTable from "./table";
import CreateLink from "../../../common/components/create-link";
import { Spinner } from "@cimpress/react-components/lib/shapes";

const LinksPanel = ({ assetId, versionId }: { assetId: string; versionId: string }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [linksGraphData, setLinksGraphData] = useState<LinksGraphData>(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [showCreateLinkModal, setShowCreateLinkModal] = useState<boolean>(false);

  const { client }: AppContextValue = useContext(AppContext);
  const assetsApi = new AssetsApi(client);

  const fetchLinks = useCallback(async () => {
    setLoading(true);

    const query = {
      limit: 100
    };

    const nodeSet = new Set<string>(),
      links: Link[] = [];

    nodeSet.add(assetId);

    const linksApi = new LinksApi(client);


    const getLinks = async (linkFromProperty) => {
      try {

        const body: LinksQueryBody = {};

        if (linkFromProperty === "sourceId") {
          body.sourceId = assetId;
        } else {
          body.targetId = assetId;
        }

        const data = await linksApi.getLinks(assetId, query);

        if (data && data.success) {
          const response = _.get(data, "response", null);

          if (response) {
            const count: number = _.get(response, "count", 0);
            if (count > 0) {
              const assetLinks = _.get(response, "_embedded.item", []);

              for (const assetLink of assetLinks) {
                if (linkFromProperty === "sourceId") {
                  nodeSet.add(assetLink.target.id);
                } else {
                  nodeSet.add(assetLink.source.id);
                }

                links.push({
                  from: assetLink.source.id,
                  to: assetLink.target.id,
                  id: assetLink.id,
                  label: assetLink.type,
                });
              }
            }
          }
        }
      } catch (error) {
        console.log("getAllLinks: ", error);
      }
    };

    await getLinks("sourceId");

    if (links.length === 0) {
      nodeSet.delete(assetId);
    }

    let nodes = [];
    if (nodeSet.size > 0) {
      nodes = await getNodes(nodeSet, assetId, assetsApi);
    }

    if (nodes.length > 1) {
      setLinksGraphData({ nodes, edges: links });
    } else {
      setLinksGraphData({ nodes: [], edges: [] });
    }

    setLoading(false);
  }, [versionId]);

  useEffect(() => {
    const ac = new AbortController();
    fetchLinks();
    return () => ac.abort();
  }, [assetId]);

  const toggleCreateLinkModal = () => setShowCreateLinkModal(!showCreateLinkModal);

  const updateSelectedTab = (e, selectedKey) => setSelectedTabIndex(selectedKey);

  const tabs = useMemo(() => {
    const tableLinks: TableLink[] =
      selectedTabIndex === 1 && linksGraphData && linksGraphData.edges.length > 0 ? getTableLinks(linksGraphData) : [];

    return [
      {
        name: "Network View",
        block: <LinksNetwork assetId={assetId} linksGraphData={linksGraphData} />,
      },
      {
        name: "Table View",
        block: <LinksTable links={tableLinks} onPageRefresh={fetchLinks} />,
      },
    ];
  }, [linksGraphData, selectedTabIndex]);

  return (
    <>
      <div className="row">
        <div className="col-md-12" style={{ marginLeft: "-15px" }}>
          <div style={{ display: "grid" }}>
            <label data-testid="lblLink">Linked Asset</label>
            <span>
              A link defines the relationship between two assets. The user can specify the type and nature of the
              relationship in any manner, and Asset Management helps to create and maintain the link.
              <br />
              These links can then be used to query both assets.
            </span>
          </div>
          <Button
            data-testid="btnCreateLink"
            variant="default"
            style={{ margin: "13px 0px 18px 0px" }}
            onClick={toggleCreateLinkModal}
          >
            Create Linked Asset
          </Button>

          {isLoading ? (
            <Spinner />
          ) : (
            <TabCard tabs={tabs} selectedIndex={selectedTabIndex} onSelect={updateSelectedTab} />
          )}
        </div>
        <CreateLink
          sourceAssetId={assetId}
          showCreateLinkModal={showCreateLinkModal}
          toggleCreateLinkModal={toggleCreateLinkModal}
        />
      </div>
    </>
  );
};

export default LinksPanel;
