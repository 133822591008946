import { request } from "http";
import _ from "lodash";

export default class HTTPClient {
  private readonly baseURL: string;
  private readonly token: string;

  constructor(baseURL: string, token: string) {
    this.baseURL = baseURL;
    this.token = token;
  }

  public async sendRequest(resource: string, options?: RequestInit) {
    const requestOptions: RequestInit = _.merge(
      {},
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
      options
    );

    return fetch(`${this.baseURL}${resource}`, requestOptions)
      .then(this.handleResponse)
      .then((response) => JSON.parse(response))
      .catch((e) => {
        //const error = JSON.parse(e);
        console.error(e);
        return e;
      });
  }

  public async sendRequestHandlingImageResponse(resource: string, options?: RequestInit) {
    const requestOptions: RequestInit = _.merge(
      {},
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
      options
    );

    return fetch(`${this.baseURL}${resource}`, requestOptions)
    .then(response => response.blob())
    .catch((e) => {
      const error = JSON.parse(e);
      return error;
    });
  }

  public async sendRequestWebPreview(id: string, options?: RequestInit) {
    const requestOptions: RequestInit = _.merge(
      {},
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
      options
    );

    return fetch(`${this.baseURL}${id}`, requestOptions)
    .then(response => {
      return response;
    })
    .catch((e) => {
      const error = JSON.parse(e);
      return error;
    });
  }

  public async sendDataToCustomURL(url: string, options?: RequestInit) {
    const requestOptions: RequestInit = _.merge(
      {},
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
      options
    );
    return fetch(url, requestOptions)
      .then(this.handleResponse)
      .then((response) => JSON.parse(response))
      .catch((e) => {
        const error = JSON.parse(e);
        console.error(error);
        return error;
      });
  }

  public async getUpload(url: string, options?: RequestInit) {
    const requestOptions: RequestInit = _.merge(
      {},
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
      options
    );
    return fetch(`${url}`, requestOptions).then((response) => response.blob());
  }

  private async handleResponse(response: any): Promise<string> {
    const contentLength = response.headers.get("content-length");

    if (!contentLength || contentLength == 0) {
      const json = {
        success: response.ok,
        status: response.status,
        statusText: response.statusText ?? "",
        response: {},
      };
      return Promise.resolve(JSON.stringify(json));
    }

    const etag = response.headers.get("etag");

    return response.json().then((json) => {
      // Modify response to include status ok, success, and status text
      let modifiedJson = {
        success: response.ok,
        status: response.status,
        statusText: response.statusText ? response.statusText : json.error || "",
        response: json,
        etag: etag ? etag : ""
      };

      // If request failed, reject and return modified json string as error
      if (!modifiedJson.success) throw JSON.stringify(modifiedJson);

      // If successful, continue by returning modified json string
      return JSON.stringify(modifiedJson);
    });
  }
}
