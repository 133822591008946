import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { AppContext, AppContextValue } from "../../app/context";
import AssetsApi from "../../common/api/assets";
import _ from "lodash";
import PageTitle from "../../common/components/pagetitle";
import Breadcrumb, { Props as BreadcrumbProps } from "../../common/components/breadcrumbs";
import { Button, Dropdown, Label } from "@cimpress/react-components";
import { Link, useParams } from "react-router-dom";
import Divider from "../../common/components/divider";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import BasicInfoPanel from "./basicinfopanel";
import TagsPanel from "./tagspanel";
import LinksPanel from "./linkspanel";
import { getModifiedAssetData, AssetVersions } from "./view-asset-helper";
import DeleteAssetModal from "../../common/components/delete-asset";
import "./view-asset-version.css";

const updateAssetButtonStyle: React.CSSProperties = {
  marginTop: "1%",
  float: "right",
};

export default function ViewAssetVersionPage() {
  const params: any = useParams();
  const { assetId, versionId, versionIndex } = params;

  const [asset, setAsset] = useState<AssetVersions>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showDeleteAssetModal, setShowDeleteAssetModal] = useState<boolean>(false);

  const { client }: AppContextValue = useContext(AppContext);

  const fetchAsset = useCallback(async () => {
    setLoading(true);

    const assetsApi = new AssetsApi(client);

    return Promise.all([assetsApi.getAssetVersions(assetId), assetsApi.getAsset(assetId)])
      .then(async (data) => {
        const blob1 = new Blob([data[1]]);
        const objectUrl = URL.createObjectURL(blob1);
        const assetVersionResponse = data[0].response._embedded.item[0];
        data[0].response._embedded.item[0]._links.links._thumbnail[0].href = objectUrl;
        return assetVersionResponse;
      })
      .then((result) => {
        setLoading(false);
        if (result) {
          setAsset(getModifiedAssetData(result));
        }
      });
  }, [versionId]);

  useEffect(() => {
    fetchAsset();
  }, [versionId]);

  const assetTitle: string = useMemo(() => {
    let _assetTitle: string = assetId;
    if (asset && asset.basicInfo && asset.basicInfo.length > 0 && asset.basicInfo[0].value) {
      const fileNameArr: string[] = asset.basicInfo[0].value.split(".");
      if (fileNameArr.length > 0) {
        _assetTitle = fileNameArr[0];
      }
    }
    return _assetTitle;
  }, [asset]);

  const breadcrumbs: BreadcrumbProps = useMemo(() => {
    return {
      items: [
        { path: "/", name: "All Assets" },
        { path: `/assets/${assetId}/versions`, name: assetTitle },
        { path: "", name: `Version ${versionId}` },
      ],
    };
  }, [assetTitle]);

  const toggleDeleteAssetModal = () => setShowDeleteAssetModal(!showDeleteAssetModal);

  return (
    <>
      <Breadcrumb {...breadcrumbs} />
      <div>
        <PageTitle title={assetTitle} />

        <div className="col-md-7">
          <div style={{ float: "right", display: "inline-block" }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ paddingRight: "15px" }}>
                    <span data-testid="lblVersion"> Version {versionId} </span>
                  </td>
                  {versionIndex === "0" && (
                    <td style={{ paddingRight: "15px" }}>
                      <Label data-testid="lblLatest" text="Latest" status="info" size="lg" />
                    </td>
                  )}
                  <td style={{ paddingRight: "15px" }}>
                    <Link
                      data-testid="updateAsset"
                      to={`/assets/update/${assetId}/versions/${versionId}/${versionIndex}`}
                    >
                      <Button variant="primary" style={updateAssetButtonStyle}>
                        Update Asset
                      </Button>
                    </Link>
                  </td>
                  <td>
                    <Dropdown
                      className="ddlViewAssetActions"
                      title={
                        <span className="h3" style={{ margin: "0" }}>
                          &#8285;
                        </span>
                      }
                      variant="simple"
                    >
                      <Button variant="anchor" onClick={toggleDeleteAssetModal}>
                        Delete Asset
                      </Button>
                    </Dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isLoading && <Spinner />}

      {!isLoading && asset && asset.basicInfo && (
        <div style={{ padding: "15px" }}>
          <div className="col-md-12" style={{ backgroundColor: "white", padding: "50px 50px 50px 65px" }}>
            <div>
              <BasicInfoPanel asset={asset} />
              <br />
              <Divider />
              <TagsPanel asset={asset} />
              <br />
              <Divider />
              <LinksPanel assetId={assetId} versionId={versionId} />
              <br />
            </div>
          </div>

          <DeleteAssetModal
            showDeleteAssetModal={showDeleteAssetModal}
            toggleDeleteAssetModal={toggleDeleteAssetModal}
            assetId={assetId}
            etag={asset["etag"]}
          />
        </div>
      )}

      <br />
      <br />
    </>
  );
}
