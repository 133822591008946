import { useState, useMemo, useEffect } from "react";
import { teal } from "@cimpress/react-components/lib/colors";
import { IconFilterAlt, IconNavigationMenu, IconLayoutModule } from "@cimpress-technology/react-streamline-icons/lib";
import { Button, Card, Checkbox, Tooltip } from "@cimpress/react-components";
import { ASSETS, COLLECTIONS } from "../../../common/utils/commonHelper";
import SearchOptionsModal, { SearchTypeKeyword } from "../search/searchOptionsModal";
import Breadcrumb from "../../../common/components/breadcrumbs";
import { Link } from "react-router-dom";

const spanViewStyle: React.CSSProperties = {
  marginTop: "2%",
  cursor: "pointer",
  display: "flex",
};

const tableViewStyle: React.CSSProperties = {
  color: teal.base,
  marginLeft: "5px",
};

const MYASSETS = "myAssets";
const MYCOLLECTIONS = "myCollections";
const HIDDENASSET = "hiddenAsset";
const SEARCHOPTIONS = "searchOptions";

const CountViewPanel = ({
  pageNo,
  currentPageCount,
  onViewToggle,
  onFilterSelection,
  onSearch,
  onClearSearch,
  breadcrumb,
  folderId,
  folderName,
}: {
  pageNo: number;
  currentPageCount: number;
  onViewToggle: any;
  onFilterSelection: any;
  onSearch: any;
  onClearSearch: any;
  breadcrumb: any;
  folderId: any;
  folderName: any;
}) => {
  const [showTableView, setShowTableView] = useState<boolean>(false);
  const [showFilterPopover, setShowFilterPopover] = useState<boolean>(false);
  const [checkedElementMap, setCheckedElementMap] = useState(new Map());
  const [isSearchOptionsChecked, setIsSearchOptionsChecked] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState("");
  const newAssetButtonStyle: React.CSSProperties = {
    marginTop: "1%",
    float: "right",
    marginRight: "2%",
  };

  const onViewClick = () => {
    setShowTableView(!showTableView);
    onViewToggle();
  };

  const viewButtonData = useMemo(() => {
    if (showTableView) {
      return {
        component: <IconLayoutModule color={teal.base} size="lg" weight="fill" />,
        label: "Grid View",
      };
    } else {
      return {
        component: <IconNavigationMenu color={teal.base} size="lg" weight="fill" />,
        label: "Table View",
      };
    }
  }, [showTableView]);

  const onFilterClick = () => setShowFilterPopover(!showFilterPopover);

  const onFilterOptionClick = (e) => {
    setCheckedElementMap((prevElementMap) => {
      let newMap = new Map(prevElementMap);
      let flag = false;

      if (!checkedElementMap.has(e.target.id) || !checkedElementMap.get(e.target.id)) {
        flag = true;
      }

      newMap.set(e.target.id, flag);
      return newMap;
    });
  };

  const onSearchFromSearchOptions = ({
    searchPayload,
    searchTypeKeyword,
  }: {
    searchPayload: any;
    searchTypeKeyword: SearchTypeKeyword[];
  }) => {
    if ("tags" in searchPayload) {
      searchPayload.tags = {
        match: "all",
        values: [searchPayload["tags"]],
      };
    }

    if ("assetId" in searchPayload && searchPayload.assetId !== "") {
      onSearch(searchPayload.assetId, true);
    } else {
      onSearch(JSON.stringify(searchPayload), false);
    }
    setFiltersApplied(JSON.stringify(searchPayload));
  };

  const onSearchOptionsClick = (e) => {
    setCheckedElementMap((prevElementMap) => {
      let newMap = new Map(prevElementMap);
      let flag = false;

      if (!checkedElementMap.has(e.target.id) || !checkedElementMap.get(e.target.id)) {
        flag = true;
        setIsSearchOptionsChecked(true);
      }
      if (!flag) {
        onClearSearch();
      }

      newMap.set(e.target.id, flag);
      return newMap;
    });
  };

  useEffect(() => {
    if (checkedElementMap.size > 0) {
      const filters = { [ASSETS]: {}, [COLLECTIONS]: {}, [HIDDENASSET]: {}, [SEARCHOPTIONS]: {} };
      for (let ele of checkedElementMap) {
        if (Array.isArray(ele) && ele.length > 1) {
          if (ele[0] === MYASSETS) {
            filters[ASSETS]["owner"] = ele[1] === true ? "me" : "";
          } else if (ele[0] === HIDDENASSET) {
            filters[ASSETS]["showHidden"] = ele[1] === true ? "true" : "false";
          }
        }
      }

      if (filters && Object.keys(filters).length > 0) {
        onFilterSelection(filters);
      }
    }
  }, [checkedElementMap]);

  const checkFolderId = () => {
    if (folderId === "") {
      folderId = "root-0";
    }
  };

  checkFolderId();

  return (
    <>
      <div className="col-md-2">
        <Breadcrumb {...breadcrumb} />
      </div>
      <div className="col-md-4" style={{}}>
        <h5>
          Page {pageNo} - Showing {currentPageCount} asset(s)
        </h5>
      </div>
      <div className="col-md-4" style={{ cursor: "pointer", display: "flex" }}></div>
      <div
        className="col-md-2"
        style={{ paddingRight: "0px", display: "flex", justifyContent: "space-evenly", flexDirection: "row-reverse" }}
      >
        <span style={{ ...spanViewStyle }} onClick={onFilterClick}>
          <Tooltip contents="Filters">
            <IconFilterAlt color={teal.base} size="lg" weight="fill" />
            {/* <span style={tableViewStyle}>Filter</span>*/}
          </Tooltip>
        </span>

        {showFilterPopover && (
          <div style={{ position: "absolute", right: "0em", top: "2em", zIndex: 1 }}>
            <Card>
              <Checkbox
                label="My Assets"
                id={MYASSETS}
                checked={checkedElementMap.has(MYASSETS) && checkedElementMap.get(MYASSETS)}
                onChange={onFilterOptionClick}
              />
              {/*<Checkbox label="My Collections" id={MYCOLLECTIONS} checked={checkedElementMap.has(MYCOLLECTIONS) && checkedElementMap.get(MYCOLLECTIONS)} onChange={onFilterOptionClick} />*/}
              <Checkbox
                label="Show Hidden Assets"
                id={HIDDENASSET}
                checked={checkedElementMap.has(HIDDENASSET) && checkedElementMap.get(HIDDENASSET)}
                onChange={onFilterOptionClick}
              />
              <Checkbox
                label="Search Options"
                id={SEARCHOPTIONS}
                checked={checkedElementMap.has(SEARCHOPTIONS) && checkedElementMap.get(SEARCHOPTIONS)}
                onChange={onSearchOptionsClick}
              />
            </Card>
          </div>
        )}
        <span style={spanViewStyle} onClick={onViewClick} data-testid="toggleView">
          <Tooltip contents="Table View">
            {viewButtonData.component}
            {/*<span style={tableViewStyle}>{viewButtonData.label}</span>*/}
          </Tooltip>
        </span>
        <Link to={`/assets/create/${folderId}/${folderName}`} data-testid="newAsset" style={{ marginTop: "-2%" }}>
          <Tooltip contents="Create New Asset">
            <span style={{ fontSize: "xx-large", color: "#006F8A" }}>+</span>
          </Tooltip>
        </Link>
      </div>
      <SearchOptionsModal
        showSearchOptionsModal={isSearchOptionsChecked}
        toggleSearchOptionsModal={() => {
          setIsSearchOptionsChecked(!isSearchOptionsChecked);
        }}
        onSearchFromSearchOptions={onSearchFromSearchOptions}
      ></SearchOptionsModal>
    </>
  );
};

export default CountViewPanel;
