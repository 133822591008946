import { Button, Tag, Tooltip } from "@cimpress/react-components";

interface Tags {
  value: string;
}

const tagRenderer = (tagsOfString: string[]): JSX.Element => {
  let remainingTags: string = "";
  let tagsCount: number = tagsOfString.length;

  if (tagsOfString.length > 3) {
    remainingTags = tagsOfString.slice(3, tagsOfString.length).join(", ");
    tagsOfString = tagsOfString.slice(0, 3);
  }

  const tags: Tags[] = tagsOfString.map((t) => {
    const _t: Tags = {
      value: t,
    };
    return _t;
  });

  return (
    <>
      {tags.map((tag) => {
        return <Tag key={tag.value} {...tag} style={{ marginRight: "3px" }} />;
      })}
      {tagsCount > 3 && (
        <>
          <Tooltip direction={"top"} contents={remainingTags}>
            <Button variant="anchor">{tagsCount - 3} more...</Button>
          </Tooltip>
        </>
      )}
    </>
  );
};

export default tagRenderer;
