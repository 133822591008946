import { Tag } from "@cimpress/react-components";

const TagsPanel = ({ asset }) => {
  return (
    <div className="row">
      <div className="col-md-12" style={{ marginLeft: "-15px" }}>
        <label data-testid="lblTags">Tags</label>
        <br />
        {asset && asset.tags.length === 0 ? (
          <span>None</span>
        ) : (
          <>
            {asset.tags.map((tag, index) => (
              <Tag
                data-testid={`value-${index}`}
                key={tag.value}
                {...tag}
                removable={false}
                style={{ marginTop: "5px", marginRight: "5px" }}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default TagsPanel;
