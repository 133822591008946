import _ from "lodash";

interface PostBodyFile {
  file: any;
  expires: any;
  tags: any;
}

interface PostBodyUri {
  uri: string;
  expires: any;
  tags: any;
}

const getTags = (tags) => tags.map((t) => t.value);

const getCreatePostBodyForFile = (postBody: PostBodyFile): FormData => {
  let data = new FormData();
  data.append("file", postBody.file);

  if (postBody.expires !== null) {
    data.append("expires", postBody.expires.value);
  }

  if (postBody.tags.length > 0) {
    data.append("tags", JSON.stringify(getTags(postBody.tags)));
  }
  return data;
};

const getCreatePostBodyForURI = (postBody: PostBodyUri): string => {
  let data = { uri: postBody.uri };

  if (postBody.expires !== null) {
    data["expires"] = postBody.expires.value;
  }

  if (postBody.tags.length > 0) {
    data["tags"] = getTags(postBody.tags);
  }

  return JSON.stringify(data);
};

const addAssetToCollection = async (collection, assetsApi, assetId, collectionsApi) => {
  let selectedCollectionId: string = collection.value;

  // create collection and then add asset into it
  if (selectedCollectionId === "createCollection") {
    const result = await collectionsApi.createCollection(collection.label);
    selectedCollectionId = _.get(result, "response.id", null);
  }

  try {
    await assetsApi.addAssetToCollection(selectedCollectionId, [assetId]);
  } catch (errCollection) {
    console.log(
      `Error on adding asset to a collection. assetId: ${assetId} collectionId: ${collection.value}`,
      errCollection
    );
  }
};

const createLink = async (linksApi, sourceAssetId, targetAssetId, linkType, version) => {
  let linkMessage = "";
  if (targetAssetId && sourceAssetId && linkType && sourceAssetId !== "" && linkType !== "") {
    try {
      await linksApi.createLink({ sourceAssetId, targetAssetId, linkType, version });
      linkMessage = " Asset Linked.";
    } catch (errLink) {
      console.log(
        `Error on creating link sourceAssetId: ${sourceAssetId} targetAssetId: ${targetAssetId} linkType: ${linkType}`,
        errLink
      );
      linkMessage = " Asset linked failed.";
    }
  }
  return linkMessage;
};

export { getCreatePostBodyForFile, getCreatePostBodyForURI, addAssetToCollection, createLink };
