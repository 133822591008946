import auth from "./auth";
import { useState, useEffect } from "react";
import HTTPClient from "../common/api";
import Spinner from "@cimpress/react-components/lib/shapes/Spinner";
import config from "../config";
import { CssLoader } from "@cimpress/react-components";
import { AppContext, AppContextValue } from "./context";
import AppHeader from "./header";
import AppLayout from "./layout";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import APP_ROUTES from "./routes";
import "./index.css";

// Login in again when token expires
auth.on("tokenExpired", () => auth.login({ nextUri: window.location.pathname, forceLogin: true }));

/**
 * Renders the base application component
 * @param props
 */
export default function App() {
  const [authenticating, setAuthenticating] = useState(true);
  const [authenticationError, setAuthenticationError] = useState(null); 

  /**
   * Attempt to login on render
   */
  useEffect(() => {
    auth
      .ensureAuthentication({
        nextUri: window.location.pathname + window.location.search,
      })
      .catch((e) => {
        setAuthenticationError(e);
      })
      .finally(() => setAuthenticating(false));
  }, [auth.isLoggedIn()]);

  const client = new HTTPClient(config.api.baseUrl, auth?.getAccessToken());

  if (authenticationError) {
    return <div>Unexpected error encountered. {authenticationError.message}</div>;
  }

  if (authenticating) {
    return <Spinner size="large" />;
  }

  return (
    <div>
      <CssLoader>
        <AppContext.Provider value={{ auth, client } as AppContextValue}>
          <AppHeader/>
          <AppLayout>
            <Router>
              <Switch>
                {APP_ROUTES.map((route, index) => {
                  return (
                    <Route key={index} exact={route.exact} path={route.path}>
                      {route.children}
                    </Route>
                  );
                })}
              </Switch>
            </Router>
          </AppLayout>
        </AppContext.Provider>
      </CssLoader>
    </div>
  );
}
