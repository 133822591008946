import HTTPClient from ".";
const queryString = require("query-string");

export default class CommonApi {
  private readonly client: HTTPClient;
  private readonly controller: AbortController;

  constructor(client: HTTPClient) {
    this.client = client;
    this.controller = new AbortController();
  }

  cancelRequest() {
    this.controller.abort();
  }

  async sendDataToLooker( data: any) {
    let requestOptions: RequestInit = {
      method: "POST",
      body: data, //for file it will be a FormData object and for URI it will JSON string
      signal: this.controller.signal,
    };
      requestOptions = {
        ...requestOptions,
        headers: {
          "Content-Type": "application/json",
        },
      };
    return this.client.sendDataToCustomURL('https://test-cimbamanager.filereview.cimpress.io/v1/publish', requestOptions);
  }
}