import { useState } from "react";
import Graph from "react-graph-vis";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import NodeModal from "./node-modal";
import { LinksGraphData, linkGraphConfig } from "../links-helper";
import "./links-graph.css";
import { Button } from "@cimpress/react-components";
import IconExpandDiagonal2 from "@cimpress-technology/react-streamline-icons/lib/IconExpandDiagonal2";

const LinksNetwork = ({ assetId, linksGraphData }: { assetId: string; linksGraphData: LinksGraphData }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [clickedNodeId, setClickedNodeId] = useState<string>("");

  const events = {
    select: (event) => {
      const { nodes, edges } = event;

      if (nodes.length === 1) {
        // when node is clicked
        if (nodes[0] !== assetId) {
          setClickedNodeId(nodes[0]);
        }
      } else if (nodes.length === 0 && edges.length === 1) {
        // when link is clicked
      }
    },
  };

  const closeNodeModal = () => setClickedNodeId("");

  const onGraphReset = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <div className="linksGraph">
      {isLoading ? (
        <Spinner />
      ) : linksGraphData && linksGraphData.edges.length > 0 ? (
        <>
          <Graph graph={linksGraphData} options={linkGraphConfig} events={events} />
          <NodeModal clickedNodeId={clickedNodeId} closeNodeModal={closeNodeModal} />
          <br />
          <Button onClick={onGraphReset} variant="primary" style={{ display: "inline-flex" }}>
            <IconExpandDiagonal2 size="lg" />
            <span style={{ marginLeft: "10px" }}>Fit to Content</span>
          </Button>
        </>
      ) : (
        <div className="nolinks">
          <h4>There is no link to view</h4>
        </div>
      )}
    </div>
  );
};

export default LinksNetwork;
