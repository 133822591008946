import { useMemo, useState, useContext } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Button, Dropdown } from "@cimpress/react-components";
import { IconCommonFileText } from "@cimpress-technology/react-streamline-icons/lib";
import { teal } from "@cimpress/react-components/lib/colors";
import tagRenderer from "../../../common/utils/tagRenderer";
import { getLocalDateString } from "../../../common/utils/dateString";
import { DropdownOptions } from "../../../common/interfaces";
import { AppContext, AppContextValue } from "../../../app/context";
import AssetsApi from "../../../common/api/assets";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

const ImageMIMETypeRegExp = new RegExp("image/*");

export interface Asset {
  id: string;
  fileName: string;
  contentType: string;
  uri: string;
  collectionId: string;
  collectionName: string;
  tags: string[];
  createdAt: string;
  owner: string;
  version: number;
  selected: boolean;
}

const homepageAssetActions: DropdownOptions[] = [
  { label: "Update Asset", value: "updateAsset" },
  { label: "Create Linked Asset", value: "createLinkedAsset" },
  // { label: "Add to Collection", value: "addToCollection" },
  // { label: "Remove from Collection", value: "removeFromCollection" },
];
// const addToCollectionIndex: number = 2;

export default function AssetsTable({
  assets,
  actions,
  showActionColumn,
}: {
  assets: any;
  actions: any;
  showActionColumn: boolean;
}): JSX.Element {
  const actionsRenderer = (cell, row) => {
    const asset: Asset = row;
    const onActionChange = (selected) => {
      if (asset !== null) {
        actions(selected.value, asset);
      }
    };

    let assetActions: DropdownOptions[] = [...homepageAssetActions];

    // if (asset.collectionId === null) {
    //   assetActions = _.filter(assetActions, (action) => action.value !== "removeFromCollection");
    // } else {
    //   assetActions[addToCollectionIndex].label = "Move to Collection";
    // }

    return (
      <Dropdown title="Actions">
        {assetActions.map((action) => {
          const _btnActionClick = () => onActionChange(action);
          return (
            <Button onClick={_btnActionClick} variant="anchor">
              {action.label}
            </Button>
          );
        })}
      </Dropdown>
    );
  };

  const { client }: AppContextValue = useContext(AppContext);
  const assetsApi = new AssetsApi(client);

  const loadProtectedImage = (asset) => {
    assetsApi.getProtectedAssetPreview(asset.uri).then((result) => {
      // Create an object URL from the data.
      const blob1 = new Blob([result]);
      const objectUrl = URL.createObjectURL(blob1);
      if (document.getElementsByClassName("img_" + asset.id).length > 0) {
        const imageElement = _.get(document.getElementsByClassName("img_" + asset.id)[0], "children[0]", "");
        imageElement["src"] = objectUrl;
      }
    });
  };
  const assetRenderer = (cell, row): JSX.Element => {
    const asset: Asset = row;
    const contentType: string = _.get(asset, "contentType", "");
    loadProtectedImage(asset);
    let image: JSX.Element;
    if (contentType === null || !contentType.match(ImageMIMETypeRegExp)) {
      image = <IconCommonFileText color={teal.base} size="4x" weight="fill" />;
    } else {
      image = (
        <div className={`img_${asset.id}`}>
          <img src={""} width="58" height="58" />
        </div>
      );
    }

    return (
      <span key={`asset-${asset.id}`} style={{ display: "inline-flex" }}>
        <Link to={`/assets/${asset.id}/versions`}>{image}</Link>
        <Link style={{ paddingLeft: "10px" }} to={`/assets/${asset.id}/versions`}>
          {asset.fileName}
        </Link>
      </span>
    );
  };

  const tagsJSX = (cell, row): JSX.Element => {
    const asset: Asset = row;
    return tagRenderer(_.get(asset, "tags", []));
  };

  const [selected, setSelected] = useState([]);

  const columns = useMemo(() => {
    const _columns = [
      {
        text: "Asset",
        dataField: "fileName",
        formatter: assetRenderer,
      },
      {
        text: "ID",
        dataField: "id",
      },
      // {
      //   text: "Collection",
      //   dataField: "collectionName",
      // },
      {
        text: "Tags",
        dataField: "tags",
        formatter: tagsJSX,
      },
      {
        text: "Created On",
        dataField: "createdAt",
      },
      {
        text: "Owner",
        dataField: "owner",
      },
    ];

    if (showActionColumn) {
      _columns.push({
        text: "Actions",
        dataField: "dummyField",
        formatter: actionsRenderer,
      });
    }

    return _columns;
  }, []);

  const data = useMemo((): Asset[] => {
    return assets.map((asset) => {
      const _asset: Asset = {
        id: asset.id,
        fileName: asset.info.storage.fileName,
        contentType: asset.info.storage.contentType,
        uri:
          asset._links.links._thumbnail && asset._links.links._thumbnail.length > 0
            ? asset._links.links._thumbnail[0]?.href
            : "",
        collectionId: asset.collectionId,
        collectionName: asset.collectionName,
        tags: asset.tags,
        createdAt: getLocalDateString(asset.createdMs),
        owner: asset.createdBy,
        version: asset.version,
        selected: asset.selected,
      };

      return _asset;
    });
  }, [assets]);

  const options = {
    sizePerPage: 10,
    hideSizePerPage: true,
  };

  const handleOnSelect = (row, isSelect) => {
    actions("selectAsset", {
      id: row.id,
      selected: isSelect,
    });
    if (isSelect) {
      setSelected([...selected, row.id]);
    } else {
      setSelected([...selected.filter((k) => k !== row.id)]);
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    actions("selectAllAsset", {
      selected: isSelect,
    });
    if (isSelect) {
      setSelected([...ids]);
    } else {
      setSelected([]);
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: selected,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  return (
    <div className="col-md-12 padding-0">
      <ToolkitProvider keyField="id" data={data} columns={columns} noDataText={"Really nothing here..."}>
        {(props) => (
          <div key={props.id}>
            <div>
              <div style={{ display: "flex", paddingBottom: "10px" }}>
                <div
                  style={{ paddingLeft: "1%", fontFamily: "Gotham-Rounded-Bold", width: "91%", alignSelf: "center" }}
                ></div>
                <div
                  className="col-xs-6 col-sm-6 col-md-6 col-lg-4 customPerformButton customExportCsv"
                  style={{ display: "contents", paddingLeft: "5px" }}
                ></div>
              </div>
            </div>
            <BootstrapTable {...props.baseProps} bordered={false} selectRow={selectRow} />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
}
