import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { AppContext, AppContextValue } from "../../app/context";
import AssetsApi from "../../common/api/assets";
import _ from "lodash";
import { Button } from "@cimpress/react-components";
import PageTitle from "../../common/components/pagetitle";
import Breadcrumb, { Props as BreadcrumbProps } from "../../common/components/breadcrumbs";
import AssetForm from "../../common/components/assetform";
import { ISnackbar } from "../../common/interfaces";
import SnackbarWrapper, { danger, success, warning } from "../../common/components/snackbarwrapper";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import { Link, useParams, useHistory } from "react-router-dom";
import { getUpdateAssetData } from "./update-asset-helper";

const pageTitle: string = "Update Asset";

const CancelSaveButtons = ({
  isOccupyFullRow,
  onSaveAssetClick,
  params,
}: {
  isOccupyFullRow: boolean;
  onSaveAssetClick: any;
  params: any;
}) => {
  return (
    <div className={isOccupyFullRow ? "col-md-12" : "col-md-7"} style={{ marginTop: "5px" }}>
      <Button variant="primary" style={{ float: "right" }} onClick={onSaveAssetClick}>
        Save Changes
      </Button>

      <Link to={`/assets/${params.assetId}/versions/${params.versionId}/${params.versionIndex}`} data-testid="cancel">
        <Button variant="default" style={{ float: "right", marginRight: "15px" }}>
          Cancel
        </Button>
      </Link>
    </div>
  );
};

let postBody = {
  isFileUploaded: true,
  uri: "",
  file: null,
  expires: null,
  tags: [],
  collection: null,
};

export default function UpdateAssetPage() {
  const history = useHistory();
  const params: any = useParams();

  const [snackbarState, setSnackbarState] = useState<ISnackbar>({
    show: false,
    message: "",
    status: success,
  });

  const [asset, setAsset] = useState(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const { client }: AppContextValue = useContext(AppContext);
  const assetsApi = new AssetsApi(client);

  const fetchAsset = useCallback(async () => {
    setLoading(true);

    const assetsApi = new AssetsApi(client);

    return Promise.all([assetsApi.getAssetVersions(params.assetId), assetsApi.getAsset(params.assetId)])
      .then((data) => {
        const blob1 = new Blob([data[1]]);
        const objectUrl = URL.createObjectURL(blob1);
        const assetVersionResponse = data[0].response._embedded.item[0];
        data[0].response._embedded.item[0]._links.links._thumbnail[0].href = objectUrl;
        const assetDetails = data[0].response._embedded.item[0].info.asset;

        if (assetVersionResponse && assetDetails) {
          assetVersionResponse["etag"] = _.get(data[1], "etag", "*");
          assetVersionResponse["collectionId"] = assetDetails ? assetDetails.collectionId : null;
        }

        return assetVersionResponse;
      })
      .then((result) => {
        setLoading(false);
        if (result) {
          setAsset(result);
        }
      });
  }, [params.versionId]);

  useEffect(() => {
    fetchAsset();
  }, [params.versionId]);

  const assetTitle: string = useMemo(() => {
    let _assetTitle: string = params.assetId;
    if (asset && asset.storage && asset.storage.fileName) {
      const fileNameArr: string[] = asset.storage.fileName.split(".");
      if (fileNameArr.length > 0) {
        _assetTitle = fileNameArr[0];
      }
    }
    return _assetTitle;
  }, [asset]);

  const breadcrumbs: BreadcrumbProps = useMemo(() => {
    return {
      items: [
        { path: "/", name: "All Assets" },
        { path: `/assets/${params.assetId}/versions`, name: assetTitle },
        {
          path: `/assets/${params.assetId}/versions/${params.versionId}/${params.versionIndex}`,
          name: `Version ${params.versionId}`,
        },
      ],
    };
  }, [assetTitle]);

  const onFormElementValueChange = (key: string, data: any) => {
    postBody = { ...postBody, [key]: data };
  };

  const onSaveAssetClick = async () => {
    if (postBody.file === null && postBody.uri === "" && postBody.expires === null && postBody.tags.length === 0) {
      setSnackbarState({
        show: true,
        status: warning,
        message: "Please edit something.",
      });
      return;
    }

    setLoading(true);

    assetsApi
      .patchAsset(
        params.assetId,
        asset.etag,
        postBody.isFileUploaded && postBody.file !== null,
        getUpdateAssetData(asset, postBody)
      )
      .then((result) => {
        if (result && result.success) {
          setSnackbarState({
            show: true,
            status: success,
            message: "Asset updated successfully.",
          });
          setTimeout(() => history.push(`/assets/${params.assetId}/versions`), 1000);
        } else {
          setLoading(false);
          const detail: string = _.get(result, "response.detail", "");
          setSnackbarState({
            show: true,
            status: danger,
            message: "Asset updation failed. " + detail,
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        setSnackbarState({
          show: true,
          status: danger,
          message: "Asset updation failed. " + e.message,
        });
      });
  };

  const hideSnackbar = () => setSnackbarState({ show: false, status: success, message: "" });

  return (
    <>
      <Breadcrumb {...breadcrumbs} />
      <div>
        <PageTitle title={pageTitle} />
        <CancelSaveButtons isOccupyFullRow={false} onSaveAssetClick={onSaveAssetClick} params={params} />
      </div>

      {isLoading && <Spinner />}

      {asset && (
        <>
          <AssetForm
            onFormElementValueChange={onFormElementValueChange}
            showManagedToggle={false}
            assetVersionData={asset}
          >
            <div className="row">
              <div className="col-md-12" style={{ marginLeft: "-15px" }}>
                <label>Links</label>
                <br />
                <span style={{ fontSize: "16px" }}>
                  Link your asset to related versions of the asset that are available in Asset Management.
                </span>
              </div>
            </div>
          </AssetForm>
          {isLoading && <Spinner />}
          <br />
          <br />
        </>
      )}

      <SnackbarWrapper {...snackbarState} onHideSnackbar={hideSnackbar} />
    </>
  );
}
