import { Snackbar } from "@cimpress/react-components";

export const danger = "danger";
export const success = "success";
export const warning = "warning";
export const info = "info";
const delaySeconds: number = 1500;

const SnackbarWrapper = ({
  show,
  status,
  message,
  onHideSnackbar,
}: {
  show: boolean;
  status: string;
  message: string;
  onHideSnackbar: any;
}) => {
  const _status = status === danger ? danger : status === warning ? warning : status === info ? info : success;
  return (
    <Snackbar data-testid="snackbar" show={show} status={_status} delay={delaySeconds} onHideSnackbar={onHideSnackbar}>
      {message}
    </Snackbar>
  );
};

export default SnackbarWrapper;
