import { useState } from "react";
import _ from "lodash";
import ProductCard from "@cimpress-technology/product-card";
import {
  IconAdd,
  IconPencilAlt,
  IconHyperlink,
  IconLayersBack,
  IconLayersFront,
} from "@cimpress-technology/react-streamline-icons/lib";

const ImageMIMETypeRegExp = new RegExp("image/*");

export default function AssetCard({
  asset,
  actions,
  onImageLoaded,
  folderName,
}: {
  asset: any;
  actions: any;
  onImageLoaded: any;
  folderName: any;
}) {
  const [selectedAsset, setSelectedAsset] = useState<boolean>(false);

  const actionOnClick = ({ actionType }) => actions(actionType, asset);

  // on selecting an asset, call actionOnClick with the selected asset
  const onAssetSelectionChanged = () => {
    actions("selectAsset", {
      id: asset.id,
      selected: !selectedAsset,
    });
    setSelectedAsset(!selectedAsset);
  };

  const contentType = _.get(asset, "storage.contentType", null);
  let imageUrl;
  if (asset.managed && (contentType === null || !contentType.match(ImageMIMETypeRegExp))) {
    imageUrl = "";
  } else {
    imageUrl = asset.uri;
  }

  const tags = asset.tags.join(", ");
  const actionsArr = [
    {
      title: "Update Asset",
      icon: <IconPencilAlt />,
      onClick: () => actionOnClick({ actionType: "updateAsset" }),
    },
    {
      title: "Create Linked Asset",
      icon: <IconHyperlink />,
      onClick: () => actionOnClick({ actionType: "createLinkedAsset" }),
    },
    /*
      title: asset.collectionId !== null ? "Move to Collection" : "Add to Collection",
      icon: <IconAdd />,
      onClick: () => actionOnClick({ actionType: "addToCollection" }),
    },*/
    {
      title: asset.info.asset.hidden ? "Unhide" : "Hide",
      icon: <IconLayersBack />,
      onClick: () => actionOnClick({ actionType: asset.info.asset.hidden ? "unHideAsset" : "hideAsset" }),
    },
  ];

  if (asset.collectionId !== null) {
    /*actionsArr.push({
      title: "Remove from Collection",
      icon: <IconAdd />,
      onClick: () => actionOnClick({ actionType: "removeFromCollection" }),
    });*/
  }
  const props = {
    size: "xl",
    orientation: "vertical",
    /* productDetails: [
      {
        key: "Owner",
        value: asset.createdBy,
      },
      {
        key: "Folder",
        value: folderName,
      },
      {
        key: "Tags",
        value: tags,
      },
    ],*/
    productName: asset.info.storage.fileName,
    uniqueId: asset.id,
    imageUrl:
      asset._links.links._thumbnail && asset._links.links._thumbnail.length > 0
        ? asset._links.links._thumbnail[0]?.href
        : "",
    imageClassName: `img_${asset.id} imageContainer image`,
    allowImageClick: true,
    isSelected: selectedAsset,
    notSelectable: false,
    loading: false,
    topLeftAction: null,
    unqiueId: asset.id,
    onSelectionChanged: onAssetSelectionChanged,
    tag: {},
    actions: actionsArr,
    hyperlink: `/assets/${asset.id}/versions`,
    //productFulfiller: "ID : " + asset.id,
    className: asset.info.asset.hidden ? "hiddenAsset" : "",
    onImageLoaded: () => onImageLoaded(asset),
  };

  if (asset.managed === false) {
    props.tag = { title: "Not managed" };
  }

  return <ProductCard {...props} />;
}
