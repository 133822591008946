import { Fragment, useContext, useState } from "react";
import Tree from "react-ui-tree";
import Icon from "react-icons-kit";
import { folder } from "react-icons-kit/feather/folder";
import { file } from "react-icons-kit/feather/file";
import { folderPlus } from "react-icons-kit/feather/folderPlus";
import { filePlus } from "react-icons-kit/feather/filePlus";
import { chevronRight } from "react-icons-kit/feather/chevronRight";
import { chevronDown, chevronLeft } from "react-icons-kit/feather";
import { ContextMenuTrigger } from "react-contextmenu";
import _, { size } from "lodash";
import { StrollableContainer } from "react-stroller";
import deepdash from "deepdash";
import AssetsApi from "../../api/assets";
import { AppContext, AppContextValue } from "../../../app/context";

import "./styles.css";
import "./theme.css";
import "./react-contextmenu.css";
import { Link } from "react-router-dom";
import CreateFolder from "../../../pages/home/create-folder";
import FoldersApi from "../../api/folders";

deepdash(_);

function collect(props) {
  return props;
}

const LightScrollbar = () => {
  return <div className="LightScrollbar"></div>;
};

const Toolbar = () => {
  return <div className="Toolbar"></div>;
};

const FloatLeft = () => {
  return <div className="FloatLeft"></div>;
};

const ToolbarFileFolder = () => {
  return <div className="ToolbarFileFolder"></div>;
};

export default function FileExplorer({
  onAssetChange,
  assets,
  setFolderName,
  setFolderId,
}: {
  onAssetChange: any;
  assets: any;
  setFolderName: any;
  setFolderId: any;
}) {
  const { client, auth }: AppContextValue = useContext(AppContext);

  const setRootFolderId = async (tree) => {
    const foldersApi = new FoldersApi(client);
    let rootId = "";
    const rootFolderquery = {
      limit: 20,
      folderName: auth?.getProfile().email,
    };
    await foldersApi.getFolders(rootFolderquery).then((res) => {
      rootId = res.response._embedded.item[0].id;
      tree.id = rootId;
    });
  };

  let tree = {
    module: auth?.getProfile().email,
    id: () => {
      setRootFolderId(tree);
    },
    children: [],
  };
  const initialState = {
    active: null,
    tree: tree,
    collapsed: false,
  };
  const [treeState, setTreeState] = useState<any>(initialState);
  const [folderSelected, setFolderSelected] = useState<any>(tree.id);
  const [showFolderCreateModal, setShowFolderCreateModal] = useState<boolean>(false);
  const [newFileClicked, setNewFileClicked] = useState<any>();
  const [isFolderView, setIsFolderView] = useState<boolean>(true);
  const assetsApi = new AssetsApi(client);

  const toggleNodeCollapse = (node) => {
    let tempTreeState = treeState;
    _.forEach(tempTreeState.tree.children, (tempNode) => {
      if (tempNode.id == node.id) {
        tempNode.collapsed = !tempNode.collapsed;
      }
    });
    setTreeState(tempTreeState);
  };

  const setSubFoldersForParentFolder = async (node) => {
    const body = {};
    const query = {
      sort: "createdMs:desc",
      showDeleted: false,
      showHidden: false,
      showFolders: true,
      showVariants: false,
      folderName: node.module,
    };
    let itemList = [];
    await assetsApi.getAssetsByQuery(JSON.stringify(body), query).then((res) => {
      itemList = res.response._embedded.item;
    });
    for (let itemCounter = 0; itemCounter < itemList.length; itemCounter++) {
      let element = itemList[itemCounter];
      let item = {};
      if (element.type == "folder") {
        item = {
          id: element.id,
          module: element.name,
          children: [],
        };
        setSubFoldersForParentFolder(item);
      } else {
        item = {
          id: element.id,
          module: element.name,
          leaf: true,
          asset: element,
        };
      }
      node.children.push(item);
    }
    let tempTreeState = treeState;
    _.forEach(tempTreeState.tree.children, (tempNode) => {
      if (tempNode.id == node.id) {
        tempNode.children = node.children;
      }
    });
    setTreeState(tempTreeState);
  };

  const getAssetsByFolderId = async (node) => {
    if (node.module != auth?.getProfile().email) {
      setSubFoldersForParentFolder(node);
    }
    const body = {
      type: "image",
    };
    const query = {
      sort: "createdMs:desc",
      showDeleted: false,
      showHidden: false,
      showFolders: true,
      showVariants: false,
      folderName: node.module,
      limit: 20,
    };
    let itemList = [];
    await assetsApi.getAssetsByQuery(JSON.stringify(body), query).then((res) => {
      itemList = res.response._embedded.item;
      onAssetChange(itemList);
    });
  };

  const renderNode = (node) => {
    const renderFileFolderToolbar = (isFolder, node) =>
      isFolder && (
        <div
          className="Toolbar"
          onClick={() => handleOnFolderClick(node)}
          style={node.id == folderSelected ? { backgroundColor: "#ECECEC", width: "80%" } : {}}
        >
          <div className="FloatLeft" style={{ display: "flex" }}>
            <Icon icon={isFolder ? folder : file} size={20} />
            <div style={{ width: "50%", textOverflow: "ellipsis", overflow: "hidden" }}>{node.module}</div>
          </div>
          <div className="ToolbarFileFolder">
            {isFolder && (
              <Fragment>
                <Icon title="New Folder" icon={folderPlus} onClick={() => toggleCreateFolderModal()} size={20} />
                <Link to={`/assets/create/${node.id}/${node.module}`} data-testid="newAsset">
                  <Icon title="New File" icon={filePlus} style={{ color: "transparent" }} size={20} />
                </Link>
              </Fragment>
            )}
          </div>
          {/*node.module===auth?.getProfile().email && <Icon
            title={isFolderView ? "expand" : "collapse"}
            icon={chevronLeft}
            onClick={toggleFileExplorer}
            size = {30}
            />*/}
        </div>
      );

    const isFolder = node.hasOwnProperty("children");
    
    const handleOnFolderClick = async (node) => {
      setFolderName(node.module);
      setFolderId(node.id);
      setFolderSelected(node.id);

      if (!_.isEmpty(node.children) && node.module != auth?.getProfile().email) {
        onAssetChange(node.children.filter((x) => Object.keys(x).includes("asset")).map((x) => x.asset));
        setTreeState({ ...treeState, collapsed: false });
        return;
      }
      getAssetsByFolderId(node);
    };
    return (
      <ContextMenuTrigger id="FILE_CONTEXT_MENU" key={node.id} collect={collect} holdToDisplay={-1}>
        {renderFileFolderToolbar(isFolder, node)}
      </ContextMenuTrigger>
    );
  };

  const addItem = async (itemType, active) => {
    const newItem =
      itemType === "folder"
        ? {
            id: `root-${Date.now()}`,
            module: `New ${itemType}`,
            children: [{ id: `root-${Date.now()}`, module: `New ${itemType}`, leaf: true }],
          }
        : { id: `root-${Date.now()}`, leaf: true, module: `New ${itemType}` };

    const newTree = deepdash(_).mapDeep(tree, (item, key, parentValue) => {
      const cloneItem = Object.assign({}, item);
      if (cloneItem) {
        if (cloneItem.id === active.id && cloneItem.children) {
          cloneItem.children.push(newItem);
        }
      }
      return cloneItem;
    });
    setTreeState({ ...newTree });
  };

  const handleChange = (tree) => {
    let collapsed = treeState.collapsed;
    setTreeState({ ...treeState, collapsed });
  };

  const setRootFolder = async () => {
    const body = {
      type: "folder",
    };
    const query = {
      showDeleted: false,
      showHidden: false,
      showFolders: true,
      showVariants: false,
      scopeToUserFolders: false,
    };
    let itemsList = [];
    await assetsApi.getAssetsByQuery(JSON.stringify(body), query).then((res) => {
      itemsList = res.response._embedded.item;
      let tempItemList = [];
      for (let itemCounter = 0; itemCounter < itemsList.length; itemCounter++) {
        let element = itemsList[itemCounter];
        let item = {};
        if (element.type == "folder") {
          item = {
            id: element.id,
            module: element.name,
            children: [],
          };
        } else {
          item = {
            id: element.id,
            module: element.info.storage.fileName,
            leaf: true,
          };
        }

        tempItemList[itemCounter] = item;
      }
      tree.children = tempItemList;
    });
  };

  setRootFolder();

  const toggleCollapse = () => {
    let collapsed = !treeState.collapsed;
    setTreeState({ ...treeState, collapsed });
  };

  const toggleCreateFolderModal = () => {
    setShowFolderCreateModal(!showFolderCreateModal);
  };

  const toggleFileExplorer = () => {
    setIsFolderView(!isFolderView);
  };

  return (
    <>
      <div style={{}}></div>
      {isFolderView ? (
        <div className="tree" style={{ display: "flex", height: "70vh" }}>
          <div className="Toolbar">
            <div className="FloatLeft">
              <Icon
                title={treeState.collapsed ? "expand" : "collapse"}
                icon={treeState.collapsed ? chevronRight : chevronDown}
                onClick={toggleCollapse}
                size={20}
              />
            </div>
          </div>
          {!treeState.collapsed ? (
            <StrollableContainer draggable bar={LightScrollbar}>
              <Tree paddingLeft={20} tree={treeState.tree} onChange={handleChange} renderNode={renderNode} />
            </StrollableContainer>
          ) : (
            <div className="Toolbar" style={{ width: "80%" }}>
              <div className="FloatLeft" style={{ display: "flex" }}>
                <Icon icon={folder} />
                <div style={{ width: "50%", textOverflow: "ellipsis", overflow: "hidden" }}>
                  {auth?.getProfile().email}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
      <CreateFolder showModal={showFolderCreateModal} toggleModal={toggleCreateFolderModal} folderId={folderSelected} />
    </>
  );
}
