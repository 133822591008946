import { useContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AppContext, AppContextValue } from "../../app/context";
import PageTitle from "../../common/components/pagetitle";
import AssetsApi from "../../common/api/assets";
import _ from "lodash";
import Breadcrumb, { Props as BreadcrumbProps } from "../../common/components/breadcrumbs";
import { Button, Dropdown, Label, Pagination, Table } from "@cimpress/react-components";
import { Link, useParams } from "react-router-dom";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import tagRenderer from "../../common/utils/tagRenderer";
import DeleteAssetModal from "../../common/components/delete-asset";
import { getLocalDateString } from "../../common/utils/dateString";
import "./manage-asset-versions.css";

const pageTitle: string = "Manage Versions";
const defaultPageOffsets: string[] = [null, null];

const updateAssetButtonStyle: React.CSSProperties = {
  marginTop: "1%",
  float: "right",
};

interface AssetVersions {
  id: string;
  version: string;
  versionIndex: string;
  modifiedAt: string;
  modifiedBy: string;
  createdBy: string;
  tags: string[];
  owner: string;
}

const tagsJSX = (data): JSX.Element => {
  const assetVersions: AssetVersions = _.get(data, "original", null);
  return tagRenderer(_.get(assetVersions, "tags", []));
};

const verionsRenderer = (data): JSX.Element => {
  const assetVersions: AssetVersions = _.get(data, "original", null);
  const id: string = _.get(assetVersions, "id", "");
  const version: string = _.get(assetVersions, "version", "");
  const index: string = _.get(assetVersions, "versionIndex", "0");

  return (
    <>
      <Link to={`/assets/${id}/versions/${version}/${index}`}>Version {version}</Link>
      {index === "0" && (
        <>
          &nbsp;
          <Label text="Latest" status="info" size="lg" />
        </>
      )}
    </>
  );
};

const columns = [
  {
    Header: "Versions",
    accessor: "version",
    Cell: verionsRenderer,
  },
  {
    Header: "Last Modified",
    accessor: "modifiedAt",
  },
  {
    Header: "Editor",
    accessor: "modifiedBy",
  },
  {
    Header: "Author",
    accessor: "createdBy",
  },
  {
    Header: "Tags",
    accessor: "tags",
    Cell: tagsJSX,
  },
  {
    Header: "Owner",
    accessor: "owner",
  },
];

export default function ManageAssetVersionsPage() {
  const params: any = useParams();

  const breadcrumbs: BreadcrumbProps = useMemo(() => {
    return {
      items: [
        { path: "/", name: "All Assets" },
        { path: "", name: pageTitle },
      ],
    };
  }, [params.assetId]);

  const [assetVersions, setAssetVersions] = useState([]);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageOffsetArray, setPageOffsetArray] = useState<string[]>(defaultPageOffsets);

  const pageOffsetArrayRef = useRef<string[]>(defaultPageOffsets);
  const pageNoRef = useRef<number>(1);

  const [showDeleteAssetModal, setShowDeleteAssetModal] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [etag, setETAG] = useState<string>("");

  const { client }: AppContextValue = useContext(AppContext);
  const assetsApi = new AssetsApi(client);

  const fetchAssetVersions = useCallback(
    async (currentOffset: string = pageOffsetArrayRef.current[pageNoRef.current]) => {
      const query = {
        limit: 20,
        offset: currentOffset,
      };

      setLoading(true);

      return assetsApi
        .getAssetVersions(params.assetId)
        .then((data) => {
          const assetVersions = _.get(data, "response._embedded.item", []);
          const offset = _.get(data, "response.offset", null);

          return {
            assetVersions,
            offset,
          };
        })
        .then((result) => {
          setLoading(false);
          if (result.assetVersions) {
            setAssetVersions(result.assetVersions);
          }

          if (result.offset) {
            const existingPageOffsetArray = [...pageOffsetArrayRef.current];
            if (pageNoRef.current + 1 === existingPageOffsetArray.length) {
              existingPageOffsetArray.push(result.offset);
              setPageOffsetArray(existingPageOffsetArray);
            }
          }
        });
    },
    [pageOffsetArrayRef]
  );

  useEffect(() => {
    pageOffsetArrayRef.current = pageOffsetArray;
  }, [pageOffsetArray]);

  useEffect(() => {
    pageNoRef.current = pageNo;
    fetchAssetVersions();
  }, [pageNo]);

  const onNavigating = ({ selected }) => setPageNo(selected + 1);

  const data = useMemo((): AssetVersions[] => {
    return assetVersions.map((versions, index) => {
      const _versions: AssetVersions = {
        id: versions.id,
        version: versions.version,
        versionIndex: index.toString(),
        modifiedAt: getLocalDateString(versions.modifiedMs),
        modifiedBy: versions.modifiedBy,
        createdBy: versions.createdBy,
        tags: versions.tags,
        owner: versions.owner,
      };

      return _versions;
    });
  }, [assetVersions]);

  const toggleDeleteAssetModal = async () => {
    if (etag === "") {
      const assetResponse = await assetsApi.getAsset(params.assetId);
      if (assetResponse) {
        const _etag: string = _.get(assetResponse, "etag", "*");
        setETAG(_etag);
      }
    }
    setShowDeleteAssetModal(!showDeleteAssetModal);
  };

  return (
    <>
      <Breadcrumb {...breadcrumbs} />
      <div>
        <PageTitle title={pageTitle} />
        <div className="col-md-7">
          <div style={{ float: "right", display: "inline-block" }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ paddingRight: "15px" }}>
                    {data.length > 0 && (
                      <Link
                        data-testid="updateAsset"
                        to={`/assets/update/${params.assetId}/versions/${data[0].version}/${0}`}
                      >
                        <Button variant="primary" style={updateAssetButtonStyle}>
                          Update Latest Version
                        </Button>
                      </Link>
                    )}
                  </td>
                  <td>
                    <Dropdown
                      className="ddlManageAssetActions"
                      title={
                        <span className="h3" style={{ margin: "0" }}>
                          &#8285;
                        </span>
                      }
                      variant="simple"
                    >
                      {/* <Button data-testid="addToCollection" variant="anchor" onClick={toggleAddAssetToCollectionModal}>
                        Add to Collection
                      </Button> */}

                      <Button data-testid="deleteAsset" variant="anchor" onClick={toggleDeleteAssetModal}>
                        Delete Asset
                      </Button>
                    </Dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isLoading && <Spinner />}
      <div className="col-md-12">
        <Table columns={columns} data={data} noDataText={"Really nothing here..."} />
      </div>
      {!isLoading && (
        <div className="col-md-12">
          <div style={{ float: "right" }}>
            <Pagination
              initialPage={pageNo - 1}
              pageCount={pageOffsetArray.length - 1}
              onPageChange={onNavigating}
              pagesOutsideElipses={0}
              pagesBetweenElipses={0}
              pageRangeDisplayed={0}
              marginPagesDisplayed={0}
              disableInitialCallback={true}
            />
          </div>
        </div>
      )}

      <DeleteAssetModal
        showDeleteAssetModal={showDeleteAssetModal}
        toggleDeleteAssetModal={toggleDeleteAssetModal}
        assetId={params.assetId}
        etag={etag}
      />

      <br />
      <br />
    </>
  );
}
