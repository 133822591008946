let actionsMap = new Map();

export const setActions = (pageName, actionName, method) => {
  let key = pageName + "-" + actionName;
  actionsMap.set(key, method);
};

export const hasActions = (pageName, actionName) => {
  let key = pageName + "-" + actionName;
  return actionsMap.has(key);
};

export const executeActions = (pageName, actionName) => {
  let key = pageName + "-" + actionName;
  let method = actionsMap.get(key);
  if (typeof method === "function") method();
};
