const PageTitle = (props) => {
  let className, style;
  if (props.className === undefined) {
    className = "col-md-5";
  } else {
    className = props.className;
  }

  if (props.style === undefined) {
    style = {};
  } else {
    style = props.style;
  }

  return (
    <div className={className} style={style}>
      <h2 style={{ fontWeight: 400 }}>{props.title}</h2>
    </div>
  );
};

export default PageTitle;
