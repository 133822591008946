import { useEffect, useState, useContext, useMemo } from "react";
import { AppContext, AppContextValue } from "../../../app/context";
import { Button, Modal, TextField } from "@cimpress/react-components";
import { ISnackbar } from "../../../common/interfaces";
import SnackbarWrapper, { danger, success } from "../../../common/components/snackbarwrapper";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import _ from "lodash";
import "./update-create-collection.css";
import TagsForm from "../../../common/components/assetform/tagsform";
import FoldersApi from "../../../common/api/folders";

export enum UpdateType {
  EDITCOLLECTION,
  CREATECOLLECTION,
  CREATECHILDCOLLECTION,
}

const CreateFolder = ({
  showModal,
  toggleModal,
  folderId,
}: {
  showModal: boolean;
  toggleModal: any;
  folderId: any;
}) => {
  const { client }: AppContextValue = useContext(AppContext);

  const [folderName, setFolderName] = useState<string>("");
  const [isCreateFolderLoading, setCreateFolderLoading] = useState<boolean>(false);
  const [snackbarState, setSnackbarState] = useState<ISnackbar>({
    show: false,
    message: "",
    status: success,
  });

  const onFolderNameChange = ({ target }) => setFolderName(target.value.trim());

  const onSubmitClick = async () => {
    await createFolder();
  };

  const createFolder = async () => {
    if (folderName !== "") {
      const foldersApi = new FoldersApi(client);

      setCreateFolderLoading(true);

      foldersApi
        .createFolders(folderName, folderId)
        .then(async (result) => {
          const id = _.get(result, "response.id", null);
          if (id !== null) {
            setCreateFolderLoading(false);
            setSnackbarState({
              show: true,
              status: success,
              message: "Folder created successfully",
            });

            toggleModal(true);
          } else {
            const detail: string = _.get(result, "response.detail", "");
            setSnackbarState({
              show: true,
              status: danger,
              message: "Folder creation failed. " + detail,
            });
            setCreateFolderLoading(false);
          }
        })
        .catch((e) => {
          setSnackbarState({
            show: true,
            status: danger,
            message: "Folder creation failed. " + e.message,
          });
          setCreateFolderLoading(false);
        });
    }
  };

  const isSubmitDisabled = useMemo(() => {
    let _isSubmitDisabled = false;
    _isSubmitDisabled = folderName === "";
    return _isSubmitDisabled;
  }, [folderName]);

  const hideSnackbar = () => setSnackbarState({ show: false, status: success, message: "" });

  return (
    <>
      <Modal
        className="create-folder-modal"
        title={"Create Folder"}
        show={showModal}
        onRequestHide={toggleModal}
        closeOnOutsideClick={true}
        footer={
          <>
            {isCreateFolderLoading && <Spinner size="medium" />}
            <Button variant="default" onClick={toggleModal} data-testid="cancel">
              Cancel
            </Button>
            &nbsp;
            <Button variant="primary" disabled={isSubmitDisabled} onClick={onSubmitClick} data-testid="submitButton">
              Save
            </Button>
          </>
        }
      >
        <>
          <div className="col-md-13">
            <TextField
              data-testid="folderName"
              label="Folder Name"
              value={folderName}
              onChange={onFolderNameChange}
              required={true}
            />
          </div>
        </>
      </Modal>
      <SnackbarWrapper {...snackbarState} onHideSnackbar={hideSnackbar} />
    </>
  );
};

export default CreateFolder;
