import HTTPClient from ".";
import queryString from "query-string";

export default class AssetsApi {
  private readonly client: HTTPClient;
  private readonly controller: AbortController;

  constructor(client: HTTPClient) {
    this.client = client;
    this.controller = new AbortController();
  }

  cancelRequest() {
    this.controller.abort();
  }

  async createAsset(isFileUploaded: boolean, data: any, contentEncoding?: string) {
    let requestOptions: RequestInit = {
      method: "POST",
      body: data, //for file it will be a FormData object and for URI it will JSON string
      signal: this.controller.signal,
    };
    if (!isFileUploaded) {
      requestOptions = {
        ...requestOptions,
        headers: {
          "Content-Type": "application/json",
        },
      };
    } else if (contentEncoding) {
      requestOptions = {
        ...requestOptions,
        headers: {
          "Content-Encoding": contentEncoding,
        },
      };
    }
    return this.client.sendRequest("/v2/assets", requestOptions);
  }

  // async putAsset(id: string, isFileUploaded: boolean, data: any, etagType: string, etag: string) {
  //   let requestOptions: RequestInit = {
  //     method: "PUT",
  //     body: data,
  //     signal: this.controller.signal,
  //     headers: {},
  //   };
  //   if (!isFileUploaded) requestOptions.headers["Content-Type"] = "application/json";
  //   requestOptions.headers[etagType] = etag;
  //   return this.client.sendRequest(`/v1/assets/${id}`, requestOptions);
  // }

  async patchAsset(id: string, etag: string, isFileUploaded: boolean, data: any, etagType: string = "If-Match") {
    let requestOptions: RequestInit = {
      method: "PATCH",
      body: data,
      signal: this.controller.signal,
      headers: {},
    };

    if (!isFileUploaded) {
      requestOptions.headers["Content-Type"] = "application/json";
    }

    requestOptions.headers[etagType] = etag;
    return this.client.sendRequest(`/v2/assets/${id}`, requestOptions);
  }

  async getAssets(params?: any) {
    const requestOptions: RequestInit = {
      method: "GET",
      signal: this.controller.signal,
    };

    const qs = queryString.stringify(params);
    return this.client.sendRequest(`/v2/assets?${qs}`, requestOptions);
  }

  async getAsset(id: string, params?: any) {
    const requestOptions: RequestInit = {
      method: "GET",
      signal: this.controller.signal,
      headers: {
        "Cache-Control": "no-cache",
      },
    };

    const qs = queryString.stringify(params);
    return this.client.sendRequestHandlingImageResponse(`/v2/assets/${id}?${qs}`, requestOptions);
  }

  async getAssetWebPreview(id: string, params?: any) {
    const requestOptions: RequestInit = {
      method: "GET",
      signal: this.controller.signal,
      headers: {
        "Cache-Control": "no-cache",
      },
    };

    const qs = queryString.stringify(params);
    return this.client.sendRequestWebPreview(`/v2/assets/${id}/webPreview?${qs}`, requestOptions);
  }

  async getAssetsByQuery(data: string, params?: any) {
    const requestOptions: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      signal: this.controller.signal,
      body: data,
    };

    const qs = queryString.stringify(params);
    return this.client.sendRequest(`/v2/assets:query?${qs}`, requestOptions);
  }

  async moveAssetToAnotherFolder(data: string, id?: any) {
    const requestOptions: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "If-Match": "*",
      },
      signal: this.controller.signal,
      body: data,
    };

    return this.client.sendRequest(`/v2/assets/${id}:move`, requestOptions);
  }

  async getAssetVersions(id: string, params?: any) {
    const requestOptions: RequestInit = {
      method: "GET",
      signal: this.controller.signal,
    };

    const qs = queryString.stringify(params);
    return this.client.sendRequest(`/v2/assets/${id}/versions?${qs}`, requestOptions);
  }

  async getAssetVersion(id: string, version: number, params?: any) {
    const requestOptions: RequestInit = {
      method: "GET",
      signal: this.controller.signal,
    };
    const qs = queryString.stringify(params);
    return this.client.sendRequestHandlingImageResponse(`/v2/assets/${id}/versions/${version}?${qs}`, requestOptions);
  }

  async deleteAsset(id: string, etag: string, etagType: string = "If-Match") {
    let requestOptions: RequestInit = {
      method: "DELETE",
      signal: this.controller.signal,
      headers: {},
    };
    requestOptions.headers[etagType] = etag;
    return this.client.sendRequest(`/v2/assets/${id}`, requestOptions);
  }

  async purgeAsset(id: string, etag: string, etagType: string = "If-Match") {
    const requestOptions: RequestInit = {
      method: "DELETE",
      headers: {},
      signal: this.controller.signal,
    };
    requestOptions.headers[etagType] = etag;
    return this.client.sendRequest(`/v2/assets/${id}?purge=true`, requestOptions);
  }

  // async addAssetToCollection(id: string, assetIds: any) {
  //   const requestOptions: RequestInit = {
  //     method: "POST",
  //     body: JSON.stringify({
  //       assetIds: assetIds,
  //     }),
  //     signal: this.controller.signal,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   return this.client.sendRequest(`/v1/collections/${id}:insert`, requestOptions);
  // }

  // async restoreAsset(id: string, expires?: string) {
  //   const requestOptions: RequestInit = {
  //     method: "POST",
  //     body: JSON.stringify({
  //       expires,
  //     }),
  //     signal: this.controller.signal,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   return this.client.sendRequest(`/v1/assets/${id}:restore`, requestOptions);
  // }

  // async updateTagsAsset(id: string, data: string, etag: string, etagType: string = "If-Match") {
  //   let requestOptions: RequestInit = {
  //     method: "PATCH",
  //     body: data,
  //     signal: this.controller.signal,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   requestOptions.headers[etagType] = etag;

  //   return this.client.sendRequest(`/v1/assets/${id}:tags`, requestOptions);
  // }

  async hideAsset(id: string) {
    const requestOptions: RequestInit = {
      method: "POST",
      body: "",
      signal: this.controller.signal,
      headers: {
        "If-Match": "*",
      },
    };
    return this.client.sendRequest(`/v2/assets/${id}:hide`, requestOptions);
  }

  async unHideAsset(id: string) {
    const requestOptions: RequestInit = {
      method: "POST",
      body: "",
      signal: this.controller.signal,
      headers: {
        "If-Match": "*",
      },
    };
    return this.client.sendRequest(`/v2/assets/${id}:unhide`, requestOptions);
  }

  async getProtectedAssetPreview(url: string) {
    const requestOptions: RequestInit = {
      method: "GET",
      signal: this.controller.signal,
    };

    return this.client.getUpload(url);
  }
}
