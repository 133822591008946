import { getLocalDateString } from "../../common/utils/dateString";
import { TagData } from "../../common/interfaces";

export interface BasicInfo {
  key: string;
  label: string;
  value: string;
}

export interface AssetVersions {
  basicInfo: BasicInfo[];
  imageUrl: string;
  tags: TagData[];
  etag: string;
}

const getDays = (expires: string) => {
  let days: string = "";
  switch (expires) {
    case "P7D":
      days = "7 Days";
      break;

    case "P30D":
      days = "30 Days";
      break;

    case "P90D":
      days = "90 Days";
      break;

    case "never":
      days = "No Expiry";
      break;

    default:
      days = expires;
      break;
  }
  return days;
};

const getModifiedAssetData = (result): AssetVersions => {
  const basicInfo: BasicInfo[] = [
    {
      key: "assetId",
      label: "Asset ID",
      value: result.id,
    },
    {
      key: "fileName",
      label: "File Name",
      value: result.info.storage && result.info.storage.fileName,
    },
    {
      key: "fileSize",
      label: "Size",
      value: result.info.storage && result.info.storage.fileSizeBytes,
    },

    {
      key: "b1",
      label: "",
      value: "breakline",
    },
    {
      key: "modifiedAt",
      label: "Last Modified",
      value: getLocalDateString(result.modifiedMs),
    },
    {
      key: "modifiedBy",
      label: "Modified By",
      value: result.modifiedBy,
    },
    {
      key: "createdAt",
      label: "Date Created",
      value: getLocalDateString(result.createdMs),
    },
    {
      key: "createdBy",
      label: "Created By",
      value: result.createdBy,
    },
    {
      key: "b2",
      label: "",
      value: "breakline",
    },
    {
      key: "expires",
      label: "Expires",
      value: getDays(result.info.asset.expires),
    },
  ];

  if (!result.managed) {
    basicInfo.push(
      {
        key: "uri",
        label: "Asset URL",
        value: result._links.links._thumbnail.length > 0 ? result._links.links._thumbnail[0].href : "",
      },
      {
        key: "b3",
        label: "",
        value: "breakline",
      },
      {
        key: "sourceUri",
        label: "Source URL",
        value: result.sourceUri,
      },
      {
        key: "b4",
        label: "",
        value: "breakline",
      }
    );
  }

  const imageUrl: string = result._links.links._thumbnail.length > 0 ? result._links.links._thumbnail[0].href : "";

  let tags: TagData[] = [];

  if (result.tags && result.tags.length > 0) {
    tags = result.tags.map((t) => {
      return {
        value: t,
      };
    });
  }

  return {
    basicInfo,
    imageUrl,
    tags,
    etag: result.etag,
  };
};
export { getModifiedAssetData };
