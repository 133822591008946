import HTTPClient from ".";
const queryString = require("query-string");

export interface LinksQueryBody {
  type?: string;
  assetId?: string;
  sourceId?: string;
  targetId?: string;
  app?: string;
  owner?: string;
}

export default class LinksApi {
  private readonly client: HTTPClient;
  private readonly controller: AbortController;

  constructor(client: HTTPClient) {
    this.client = client;
    this.controller = new AbortController();
  }

  cancelRequest() {
    this.controller.abort();
  }

  async getLinks(id: string, params?: any) {
    const requestOptions: RequestInit = {
      method: "GET",
      signal: this.controller.signal,
    };

    const qs = queryString.stringify(params);
    return this.client.sendRequest(`/v2/assets/${id}/links?${qs}`, requestOptions);
  }

  async getAllLinks(params?: any) {
    const requestOptions: RequestInit = {
      method: "GET",
      signal: this.controller.signal,
    };

    const qs = queryString.stringify(params);
    return this.client.sendRequest(`/v2/links?${qs}`, requestOptions);
  }

  async getLinksByQuery(data: LinksQueryBody, params?: any) {
    const requestOptions: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      signal: this.controller.signal,
      body: JSON.stringify(data),
    };

    const qs = queryString.stringify(params);
    return this.client.sendRequest(`/v1/links:query?${qs}`, requestOptions);
  }

  async getLink(id: string, params?: any) {
    const requestOptions: RequestInit = {
      method: "GET",
      signal: this.controller.signal,
    };

    const qs = queryString.stringify(params);
    return this.client.sendRequest(`/v1/links/${id}?${qs}`, requestOptions);
  }

  async createLink({ sourceAssetId, targetAssetId, linkType, version}) {
    const requestOptions: RequestInit = {
      method: "POST",
      body: JSON.stringify({
        source: { id: sourceAssetId, version: version },
        target: { id: targetAssetId, version: version },
        type: linkType,
        unique: true,
      }),
      signal: this.controller.signal,
      headers: {
        "Content-Type": "application/json",
      },
    };

    return this.client.sendRequest(`/v2/links`, requestOptions);
  }

  async putLink(id: string, etag: string, { sourceAssetId, targetAssetId, linkType }) {
    const requestOptions: RequestInit = {
      method: "PUT",
      body: JSON.stringify({
        sourceId: sourceAssetId,
        targetId: targetAssetId,
        type: linkType,
      }),
      signal: this.controller.signal,
      headers: {
        "If-Match": etag,
        "Content-Type": "application/json",
      },
    };

    return this.client.sendRequest(`/v1/links/${id}`, requestOptions);
  }

  async deleteLink(id: string, etag: string) {
    const requestOptions: RequestInit = {
      method: "DELETE",
      headers: {
        "If-Match": etag,
      },
      signal: this.controller.signal,
    };
    return this.client.sendRequest(`/v1/links/${id}`, requestOptions);
  }
}
