import { Label, CopyText, colors, CopyButton } from "@cimpress/react-components";
import PreviewImage from "../../../common/components/preview-image";
import { slate } from "@cimpress/react-components/lib/colors";
import { IconHyperlink } from "@cimpress-technology/react-streamline-icons/lib";

const BasicInfoPanel = ({ asset }) => {
  return (
    <>
      <div className="row">
        <label data-testid="lblBasicInfo">Basic Info</label>
      </div>
      <div className="row"></div>
      <div className="row">
        <div className="col-md-8" style={{ marginLeft: "-15px" }}>
          <table>
            <tbody>
              {asset.basicInfo.map((b, index) => {
                return b.value === "breakline" ? (
                  <tr key={b.key}>
                    <td style={{ padding: "10px" }} colSpan={2}></td>
                  </tr>
                ) : (
                  <tr key={b.key}>
                    <td style={{ width: "100px" }}>
                      {b.label !== "" && <h6 style={{ color: slate }}>{b.label} :</h6>}
                    </td>
                    <td style={{ paddingLeft: "15px" }}>
                      {b.key === "assetId" ? (
                        <CopyText>{b.value}</CopyText>
                      ) : (
                        <span data-testid={`value-${index}`}>{b.value ? b.value : "None"}</span>
                      )}

                      {b.value !== null && (b.key === "uri" || b.key === "sourceUri") && (
                        <>
                          <br />

                          <CopyButton
                            variant="anchor"
                            value={b.value}
                            successMessage="Copied!"
                            style={{ marginTop: "10px" }}
                          >
                            <span style={{ display: "inline-flex" }}>
                              <IconHyperlink color={colors.ocean.base} size="sm" />
                              &nbsp; <span style={{ fontSize: "10px" }}>Copy {b.label}</span>
                            </span>
                          </CopyButton>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="col-md-4" style={{ marginBottom: "15px" }}>
          <PreviewImage
            imageUrl={asset.imageUrl}
            fileName={asset.basicInfo.length > 2 && asset.basicInfo[2].value !== "" ? asset.basicInfo[2].value : ""}
          />
        </div>
      </div>
    </>
  );
};

export default BasicInfoPanel;
