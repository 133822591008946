import _ from "lodash";

interface PostBodyFile {
  file: any;
  expires: any;
  tags: any;
}

interface PostBodyUri {
  uri: string;
  expires: any;
  tags: any;
}

const getTags = (tags) => tags.map((t) => t.value);

const getPatchBodyForFile = (postBody: PostBodyFile, asset: any): FormData => {
  let data = new FormData();
  data.append("file", postBody.file);

  if (postBody.expires !== null) {
    data.append("expires", postBody.expires.value);
  }

  if (postBody.tags.length > 0) {
    data.append("tags", JSON.stringify(getTags(postBody.tags)));
  }

  return data;
};

const getPatchBody = (postBody: PostBodyUri, asset: any): string => {
  let data = {};

  if (postBody.uri !== "") {
    data["uri"] = postBody.uri;
  }

  if (postBody.expires !== null) {
    data["expires"] = postBody.expires.value;
  }

  if (postBody.tags.length > 0) {
    data["tags"] = getTags(postBody.tags);
  }

  return JSON.stringify(data);
};

const getUpdateAssetData = (asset: any, postBody: any) => {
  let data: any;
  if (postBody.isFileUploaded && postBody.file !== null) {
    data = getPatchBodyForFile(
      {
        file: postBody.file,
        expires: postBody.expires,
        tags: postBody.tags,
      },
      asset
    );
  } else {
    data = getPatchBody(
      {
        uri: postBody.uri,
        expires: postBody.expires,
        tags: postBody.tags,
      },
      asset
    );
  }
  return data;
};

export { getPatchBodyForFile, getPatchBody, getUpdateAssetData };
