const betaUrl: string = process.env.REACT_APP_SHERBERT_API_BETA;
const stage: string = process.env.REACT_APP_SHERBERT_API_TARGET_ENV;
const scheme = stage === "local" ? "http" : "https";
let baseUrl = "";

  if (localStorage.getItem('sherbertBaseURL') && localStorage.getItem('sherbertBaseURL').length > 0) {
    baseUrl = localStorage.getItem("sherbertBaseURL");
  } else {
    baseUrl = process.env.REACT_APP_SHERBERT_API_BASE_URL;
  }

function generateBaseUrl(baseUrl: string) {
  return `${scheme}://${baseUrl}`;
}

const config = {
  applicationName: "Asset Management",
  stage,
  api: {
    pollIntervalMs: 15 * 1000,
    baseUrl: generateBaseUrl(baseUrl),
  },
};

export default config;
