import { useContext, useState } from "react";
import { AppContext, AppContextValue } from "../../app/context";
import PageTitle from "../../common/components/pagetitle";
import AssetsApi from "../../common/api/assets";
import LinksApi from "../../common/api/links";
import _ from "lodash";
import { Button } from "@cimpress/react-components";
import Breadcrumb, { Props as BreadcrumbProps } from "../../common/components/breadcrumbs";
import { Link } from "react-router-dom";
import AssetForm from "../../common/components/assetform";
import { ISnackbar } from "../../common/interfaces";
import { useHistory, useParams } from "react-router-dom";
import SnackbarWrapper, { danger, warning, success } from "../../common/components/snackbarwrapper";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import { getCreatePostBodyForFile, getCreatePostBodyForURI, createLink } from "./create-asset-helper";
import { hasActions, executeActions } from "../../app/lib/actions";

const pageTitle: string = "New Asset";

const CancelCreateButtons = ({
  isCreateAssetDisabled,
  isOccupyFullRow,
  onCreateAssetClick,
}: {
  isCreateAssetDisabled: boolean;
  isOccupyFullRow: boolean;
  onCreateAssetClick: any;
}) => {
  return (
    <div className={isOccupyFullRow ? "col-md-12" : "col-md-7"} style={{ marginTop: "5px" }}>
      <Button
        variant="primary"
        disabled={isCreateAssetDisabled}
        style={{ float: "right" }}
        onClick={onCreateAssetClick}
      >
        Create Asset
      </Button>

      {hasActions(CreateAssetPageName, "onCancel") ? (
        executeActions(CreateAssetPageName, "onCancel")
      ) : (
        <Link to={`/`} data-testid="cancel">
          <Button variant="default" style={{ float: "right", marginRight: "15px" }}>
            Cancel
          </Button>
        </Link>
      )}
    </div>
  );
};

let postBody = {
  isFileUploaded: true,
  uri: "",
  file: null,
  expires: null,
  tags: [],
};

export const CreateAssetPageName = "create-asset-page";

export default function CreateAssetPage() {
  const history = useHistory();
  const params: any = useParams();
  const [createdAsset, setCreatedAsset] = useState();

  const [snackbarState, setSnackbarState] = useState<ISnackbar>({
    show: false,
    message: "",
    status: success,
  });
  const breadcrumbs: BreadcrumbProps = {
    items: [
      { path: "/", name: "All Assets" },
      { path: "", name: params.folderName == "" ? "" : params.folderName },
    ],
  };
  const [isCreateAssetDisabled, setIsCreateAssetDisabled] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(false);

  const { client }: AppContextValue = useContext(AppContext);
  const assetsApi = new AssetsApi(client);

  const onFormElementValueChange = (key: string, data: any) => {
    postBody = { ...postBody, [key]: data };
    if (postBody.file !== null || postBody.uri !== "") {
      if (isCreateAssetDisabled) {
        setIsCreateAssetDisabled(false);
      }
    } else if (!isCreateAssetDisabled) {
      setIsCreateAssetDisabled(true);
    }
  };

  const onCreateAssetClick = async () => {
    let data: any;
    if (postBody.isFileUploaded) {
      if (postBody.file === null) {
        setSnackbarState({
          show: true,
          status: warning,
          message: "Please upload a file.",
        });
        return;
      }

      data = getCreatePostBodyForFile({
        file: postBody.file,
        expires: postBody.expires,
        tags: postBody.tags,
      });
    } else {
      if (postBody.uri === "") {
        setSnackbarState({
          show: true,
          status: warning,
          message: "Please enter Asset URL.",
        });
        return;
      }

      data = getCreatePostBodyForURI({
        uri: postBody.uri,
        expires: postBody.expires,
        tags: postBody.tags,
      });
    }

    setLoading(true);

    assetsApi
      .createAsset(postBody.isFileUploaded, data)
      .then(async (result) => {
        if (result && result.success) {
          const assetId = _.get(result, "response.id", null);
          const version = _.get(result, "response.version", null);
          setCreatedAsset(result.response);

          const linkMessage = await createLink(
            new LinksApi(client),
            params.sourceAssetId,
            assetId,
            params.linkType,
            version
          );

          setSnackbarState({
            show: true,
            status: success,
            message: "Asset created successfully." + linkMessage,
          });
          if (!_.isEmpty(params.id)) {
            const body = {
              folderId: params.id,
            };
            const res = await assetsApi.moveAssetToAnotherFolder(JSON.stringify(body), assetId);
          }
          setLoading(false);
          setTimeout(() => history.push("/"), 1000);
        } else {
          setLoading(false);
          const detail: string = _.get(result, "response.detail", "");
          setSnackbarState({
            show: true,
            status: danger,
            message: "Asset creation failed. " + detail,
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        setSnackbarState({
          show: true,
          status: danger,
          message: "Asset creation failed. " + e.message,
        });
      });
  };

  const hideSnackbar = () => setSnackbarState({ show: false, status: success, message: "" });

  return (
    <>
      {hasActions(CreateAssetPageName, "onCancel") ? null : <Breadcrumb {...breadcrumbs} />}
      <div>
        <PageTitle title={pageTitle} />
        <CancelCreateButtons
          isCreateAssetDisabled={isCreateAssetDisabled}
          isOccupyFullRow={true}
          onCreateAssetClick={onCreateAssetClick}
        />
      </div>

      {isLoading && <Spinner />}

      <AssetForm onFormElementValueChange={onFormElementValueChange}>
        <div className="row">
          <div className="col-md-12" style={{ marginLeft: "-15px" }}>
            <label>Links</label>
            <br />
            <span style={{ fontSize: "16px" }}>
              Link your asset to related versions of the asset that are available in Asset Management. You can only do
              this after creating your asset.
            </span>
          </div>
        </div>
      </AssetForm>
      {isLoading && <Spinner />}

      <br />
      <br />
      <SnackbarWrapper {...snackbarState} onHideSnackbar={hideSnackbar} />
    </>
  );
}
