import { useCallback, useContext, useEffect, useRef, useState, useMemo } from "react";
import { AppContext, AppContextValue } from "../../app/context";
import AssetCard from "./card";
import AssetsTable from "./table";
import AssetsApi from "../../common/api/assets";
import _ from "lodash";
import { Button, Dropdown, Pagination, Tooltip } from "@cimpress/react-components";
import { useHistory } from "react-router-dom";
import Breadcrumb, { Props as BreadcrumbProps } from "../../common/components/breadcrumbs";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import CountViewPanel from "./count-view-panel";
import CreateLink from "../../common/components/create-link";
import DeleteAssetModal from "../../common/components/delete-asset";
import { updatePropsForFilters, ASSETS } from "../../common/utils/commonHelper";
import HideAsset from "../../common/components/hide-asset";
import FileExplorer from "../../common/components/fileExplorer";
import Icon from "react-icons-kit";
import { chevronRight, chevronDown, chevronLeft } from "react-icons-kit/feather";
import auth from "../../app/auth";

const pageTitle: string = "All Assets";
const defaultPageOffsets: string[] = [null, null];

const appendCollectionNameInAssets = (data) => {
  let assets = _.get(data, "response._embedded.item", []);
  const collections = _.get(data, "response._embedded.collection", []);

  if (assets.length > 0 && collections.length > 0) {
    assets = assets.map((asset) => {
      let collectionName = "";

      if (asset.collectionId) {
        const matchedCollection = collections.filter((col) => col.id === asset.collectionId);
        if (matchedCollection.length > 0) {
          collectionName = matchedCollection[0].name;
        }
      }

      return {
        ...asset,
        collectionName,
      };
    });
  }
  return assets;
};

export default function Home() {
  const history = useHistory();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [assets, setAssets] = useState([]);
  const [collectionBreadcrumbs, setCollectionBreadcrumbs] = useState([]);
  const [showTableView, setShowTableView] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [filters, setFilters] = useState({});

  const [showCreateLinkModal, setShowCreateLinkModal] = useState<boolean>(false);
  const [showDeleteAssetModal, setShowDeleteAssetModal] = useState<boolean>(false);
  const [hideAsset, setHideAsset] = useState<boolean>(false);

  const [assetForAction, setAssetsForActions] = useState([]);
  const [selectedAssetFroGroupAction, setSelectedAssetFroGroupAction] = useState(new Map());

  const [pageNo, setPageNo] = useState<number>(1);
  const [pageOffsetArray, setPageOffsetArray] = useState<string[]>(defaultPageOffsets);

  const filtersRef = useRef({});
  const pageOffsetArrayRef = useRef<string[]>(defaultPageOffsets);
  const pageNoRef = useRef<number>(1);
  const [folderName, setFolderName] = useState("");
  const [folderId, setFolderId] = useState("");
  const [isFolderView, setIsFolderView] = useState<boolean>(true);

  const { client }: AppContextValue = useContext(AppContext);
  const assetsApi = new AssetsApi(client);

  const fetchAssets = useCallback(
    async (currentOffset: string = pageOffsetArrayRef.current[pageNoRef.current]) => {
      const query = {
        limit: 20,
        offset: currentOffset,
      };

      updatePropsForFilters(ASSETS, filtersRef.current, query);

      setLoading(true);

      return assetsApi
        .getAssets(query)
        .then((data) => {
          const folderName = _.get(data, "response.folderName", "");
          const assets = appendCollectionNameInAssets(data);
          const offset = _.get(data, "response.offset", null);

          return {
            folderName,
            assets,
            offset,
          };
        })
        .then((result) => {
          setLoading(false);
          if (result.assets) {
            setFolderName(result.folderName);
            setAssets(result.assets);
          }

          if (result.offset) {
            const existingPageOffsetArray = [...pageOffsetArrayRef.current];
            if (pageNoRef.current + 1 === existingPageOffsetArray.length) {
              existingPageOffsetArray.push(result.offset);
              setPageOffsetArray(existingPageOffsetArray);
            }
          }
        });
    },
    [pageOffsetArrayRef]
  );

  const clearSearch = () => {
    setSearchText("");
    fetchAssets(null);
  };

  const searchAssets = (search: string, isSearchById: boolean = false) => {
    setSearchText(search);
    setLoading(true);

    if (isSearchById) {
      return assetsApi
        .getAssetVersions(search)
        .then((data) => {
          const asset = _.get(data, "response._embedded.item[0]", null);

          return {
            assets: asset === null || !data.success ? [] : [asset],
          };
        })
        .then((result) => {
          setLoading(false);
          if (result.assets) {
            setAssets(result.assets);
          }
        });
    } else {
      const query = {
        limit: 50,
        offset: null,
      };

      return assetsApi
        .getAssetsByQuery(search, query)
        .then((data) => {
          const assets = appendCollectionNameInAssets(data);

          return {
            assets,
          };
        })
        .then((result) => {
          setLoading(false);
          if (result.assets) {
            setAssets(result.assets);
          }
        });
    }
  };

  useEffect(() => {
    pageOffsetArrayRef.current = pageOffsetArray;
  }, [pageOffsetArray]);

  useEffect(() => {
    filtersRef.current = filters;
    if (filters && filters[ASSETS] && Object.keys(filters[ASSETS]).length > 0) {
      fetchAssets();
    }
  }, [filters]);

  useEffect(() => {
    pageNoRef.current = pageNo;
    fetchAssets();
  }, [pageNo]);

  const onNavigating = ({ selected }) => setPageNo(selected + 1);

  const changeView = () => {
    setShowTableView(!showTableView);
    if (selectedAssetFroGroupAction) {
      setSelectedAssetFroGroupAction(new Map());
    }
  };

  const onFilterSelection = (filters: any) => setFilters(filters);

  const breadcrumbs: BreadcrumbProps = useMemo(() => {
    const items = [
      { path: "", name: pageTitle },
      { path: "", name: folderName == auth?.getProfile().email ? "" : folderName },
    ];

    if (collectionBreadcrumbs.length > 0) {
      collectionBreadcrumbs.forEach((breadcrumb) => {
        items.push({ path: "", name: breadcrumb });
      });
    } else {
      items.push({ path: "", name: "" });
    }

    return {
      items,
    };
  }, [folderName]);

  const onAssetActions = (actionType: string, asset: any) => {
    switch (actionType) {
      case "updateAsset":
        history.push(`/assets/update/${asset.id}/versions/${asset.version}/0`);
        break;

      case "createLinkedAsset":
        setAssetsForActions([asset.id]);
        setShowCreateLinkModal(true);
        break;
      case "hideAsset":
        setAssetsForActions([asset.id]);
        setHideAsset(true);
        break;
      case "unHideAsset":
        setAssetsForActions([asset.id]);
        setHideAsset(false);
        break;
      case "selectAsset":
        setSelectedAssetFroGroupAction((prevSelectedAsset) => {
          let newMap = new Map(prevSelectedAsset);
          newMap.set(asset.id, asset.selected);
          return newMap;
        });
        break;
      case "selectAllAsset":
        setSelectedAssetFroGroupAction((prevSelectedAsset) => {
          let newMap = new Map(prevSelectedAsset);
          assets.forEach((a) => {
            newMap.set(a.id, asset.selected);
          });
          return newMap;
        });
        break;
    }
  };

  const showGroupAction: boolean = useMemo(() => {
    let show = false;
    if (selectedAssetFroGroupAction.size > 0) {
      selectedAssetFroGroupAction.forEach((selected) => {
        if (selected === true) {
          show = true;
        }
      });
    }
    return show;
  }, [selectedAssetFroGroupAction]);

  const toggleCreateLinkModal = () => setShowCreateLinkModal(!showCreateLinkModal);

  const toggleHideAsset = () => {
    fetchAssets();
  };

  const getAssetIdsFromMap = (): string[] => {
    const assetIdArr: string[] = [];
    selectedAssetFroGroupAction.forEach((selected, assetId) => {
      if (selected === true) {
        assetIdArr.push(assetId);
      }
    });
    return assetIdArr;
  };

  const deleteSelectedAssets = () => {
    const assetIdArr: string[] = getAssetIdsFromMap();
    if (assetIdArr.length > 0) {
      setAssetsForActions(assetIdArr);
      setShowDeleteAssetModal(true);
    }
  };

  const toggleDeleteAssetModal = (refreshPage: boolean = false) => {
    if (typeof refreshPage === "boolean" && refreshPage) {
      fetchAssets();
      if (selectedAssetFroGroupAction) {
        setSelectedAssetFroGroupAction(new Map());
      }
    }
    setShowDeleteAssetModal(!showDeleteAssetModal);
  };

  const onFileAssets = (tempAssets) => {
    setAssets(tempAssets);
  };

  const onSetFolderName = (folderName) => {
    setFolderName(folderName);
  };

  const onSetFolderId = (folderId) => {
    setFolderId(folderId);
  };

  const loadProtectedImage = (asset) => {
    assetsApi
      .getProtectedAssetPreview(
        asset._links.links._thumbnail && asset._links.links._thumbnail.length > 0
          ? asset._links.links._thumbnail[0]?.href
          : ""
      )
      .then((result) => {
        // Create an object URL from the data.
        const blob1 = new Blob([result]);
        const objectUrl = URL.createObjectURL(blob1);
        if (document.getElementsByClassName("img_" + asset.id).length > 0) {
          const imageElement = _.get(document.getElementsByClassName("img_" + asset.id)[0], "children[0]", "");
          imageElement["src"] = objectUrl;
        }
      });
  };

  const toggleFileExplorer = () => {
    setIsFolderView(!isFolderView);
  };

  return (
    <>
      <div className="row" style={{ borderBottom: "1px solid #c4cdd6" }}>
        <div className="col-md-12" style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Tooltip contents="Hide Unhide Explorer">
            <Icon
              icon={isFolderView ? chevronRight : chevronLeft}
              onClick={toggleFileExplorer}
              size={25}
              style={{ color: "#006F8A", marginTop: "5px" }}
            />
          </Tooltip>
          <CountViewPanel
            pageNo={pageNo}
            currentPageCount={assets.length}
            onViewToggle={changeView}
            onFilterSelection={onFilterSelection}
            onSearch={searchAssets}
            onClearSearch={clearSearch}
            breadcrumb={{ ...breadcrumbs }}
            folderId={folderId}
            folderName={folderName}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-2" style={{}}>
          {isFolderView && (
            <div className="fileExplorer" style={{ whiteSpace: "nowrap" }}>
              <FileExplorer
                assets={assets}
                onAssetChange={onFileAssets}
                setFolderName={onSetFolderName}
                setFolderId={onSetFolderId}
              />
            </div>
          )}
        </div>
        <div className={isFolderView ? "col-md-10" : "col-md-12"} style={{}}>
          {showGroupAction && (
            <div className="" style={{ marginLeft: "-15px", marginBottom: "10px" }}>
              <div className="" style={{ display: "inline-flex" }}>
                &nbsp;
                <Button variant="primary" onClick={deleteSelectedAssets}>
                  delete Asset(s)
                </Button>
                &nbsp;
                <br />
              </div>
            </div>
          )}

          {isLoading && <Spinner />}

          {!isLoading && (
            <div style={{ overflowY: "scroll", height: "70vh" }}>
              {showTableView ? (
                <AssetsTable assets={assets} actions={onAssetActions} showActionColumn={true} />
              ) : (
                <>
                  <div
                    className="assetCard"
                    style={{
                      display: "flex",
                      justifyContent: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {assets.map((asset, id) => {
                      return (
                        <div key={id} style={{ margin: "0.3em" }}>
                          <AssetCard
                            asset={asset}
                            actions={onAssetActions}
                            onImageLoaded={loadProtectedImage}
                            folderName={folderName}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="row">
        {searchText === "" && !isLoading && (
          <div className="">
            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
              <Pagination
                initialPage={pageNo - 1}
                pageCount={pageOffsetArray.length - 1}
                onPageChange={onNavigating}
                pagesOutsideElipses={0}
                pagesBetweenElipses={0}
                pageRangeDisplayed={0}
                marginPagesDisplayed={0}
                disableInitialCallback={true}
              />
            </div>
          </div>
        )}
      </div>
      <div>
        {assetForAction.length > 0 && (
          <CreateLink
            sourceAssetId={assetForAction[0]}
            showCreateLinkModal={showCreateLinkModal}
            toggleCreateLinkModal={toggleCreateLinkModal}
          />
        )}

        {assetForAction.length > 0 && hideAsset == true && (
          <HideAsset assetIds={assetForAction[0]} hide={hideAsset} toggleHideAsset={toggleHideAsset} />
        )}

        {assetForAction.length > 0 && (
          <DeleteAssetModal
            showDeleteAssetModal={showDeleteAssetModal}
            toggleDeleteAssetModal={toggleDeleteAssetModal}
            assetIds={assetForAction}
            etag={"*"}
          />
        )}
      </div>
    </>
  );
}
