import { Button, CopyText, Modal } from "@cimpress/react-components";
import { slate } from "@cimpress/react-components/lib/colors";
import { useHistory } from "react-router-dom";
import { getNodeDetails } from "../links-helper";
import "./links-graph.css";

const NodeModal = ({ clickedNodeId, closeNodeModal }: { clickedNodeId: string; closeNodeModal: any }) => {
  const history = useHistory();

  const rediectToAsset = () => history.push(`/assets/${clickedNodeId}/versions`);

  const nodeDetails = getNodeDetails();

  return (
    <Modal
      className="nodeModal"
      show={clickedNodeId !== ""}
      onRequestHide={closeNodeModal}
      closeOnOutsideClick={true}
      title={clickedNodeId !== "" && nodeDetails[clickedNodeId] && nodeDetails[clickedNodeId].fileName}
      footer={
        <>
          <Button variant="default" onClick={closeNodeModal} data-testid="cancel">
            Cancel
          </Button>
          &nbsp;
          <Button variant="primary" onClick={rediectToAsset} data-testid="viewAsset">
            View Asset
          </Button>
        </>
      }
    >
      {clickedNodeId !== "" && nodeDetails[clickedNodeId] && (
        <div className="row col-md-12">
          <table>
            <tbody>
              <tr>
                <td>
                  <h6 style={{ color: slate }}>Asset Id :</h6>
                </td>
                <td className="tdValues">
                  <CopyText>{clickedNodeId}</CopyText>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 style={{ color: slate }}>Size :</h6>{" "}
                </td>
                <td className="tdValues">
                  <span data-testid="size">{nodeDetails[clickedNodeId].size}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 style={{ color: slate }}>Tags :</h6>
                </td>
                <td className="tdValues">
                  <span data-testid="tags"> {nodeDetails[clickedNodeId].tags.join(", ")}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 style={{ color: slate }}>Owner :</h6>
                </td>
                <td className="tdValues">
                  <span data-testid="owner">{nodeDetails[clickedNodeId].owner}</span>{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </Modal>
  );
};

export default NodeModal;
