import { Button, Modal } from "@cimpress/react-components";
import { useState, useContext } from "react";
import _ from "lodash";
import { slate } from "@cimpress/react-components/lib/colors";
import { AppContext, AppContextValue } from "../../../../app/context";
import LinksApi from "../../../../common/api/links";
import { danger, success } from "../../../../common/components/snackbarwrapper";
import { Spinner } from "@cimpress/react-components/lib/shapes";

const DeleteLinkModal = ({
  showModal,
  toggleModal,
  onDisplayMessage,
  linkId,
  linkType,
  assetName,
}: {
  showModal: boolean;
  toggleModal: any;
  onDisplayMessage: any;
  linkId: string;
  linkType: string;
  assetName: string;
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const { client }: AppContextValue = useContext(AppContext);

  const onDeleteLink = () => {
    const linksApi = new LinksApi(client);

    setLoading(true);

    linksApi
      .deleteLink(linkId, "*")
      .then((result) => {
        setLoading(false);

        if (result && result.status) {
          onDisplayMessage({
            message: "Link deleted successfully.",
            status: success,
          });
        } else {
          const detail: string = _.get(result, "response.detail", "");
          onDisplayMessage({
            message: "Link deletion failed. " + detail,
            status: danger,
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        onDisplayMessage({
          message: "Link deletion failed. " + e.message,
          status: danger,
        });
      });
  };

  return (
    <>
      <Modal
        show={showModal}
        onRequestHide={toggleModal}
        closeOnOutsideClick={true}
        title="Are you sure you want to delete this Link?"
        footer={
          <>
            {isLoading && <Spinner size="medium"/>}
            <Button variant="default" onClick={toggleModal} data-testid="cancel">
              Cancel
            </Button>
            &nbsp;
            <Button variant="primary" data-testid="deleteLink" onClick={onDeleteLink}>
              Delete
            </Button>
          </>
        }
      >
        <table>
          <tbody>
            <tr>
              <td>
                <h6 style={{ color: slate }}>Linked Asset :</h6>
              </td>
              <td style={{ paddingLeft: "15px" }} data-testid="assetName">
                {assetName}
              </td>
            </tr>
            <tr>
              <td>
                <h6 style={{ color: slate }}>Link Type :</h6>
              </td>
              <td style={{ paddingLeft: "15px" }} data-testid="linkType">
                {linkType}
              </td>
            </tr>
          </tbody>
        </table>
      </Modal>
    </>
  );
};

export default DeleteLinkModal;
